
const Datasets = {
  "prop1": "Project folders", // first word capitalized & plural | Ex: Data sets
  "prop2": "Project Folders", // all words capitalized & plural | Ex: Data Sets
  "prop3": "Project Folder", // all words capitalized & singular | Ex: Data Set
  "prop4": "Project folder", // first word capitalized & singular | Ex: Data set
  "prop5": "project folder", // all words lower case & singular | Ex: data set
  "prop6": "project folders", // all works lower case & plural | Ex: data sets
  "prop7": "/projectfolders" // used for URL
};

const Workspaces  = {
  myStudies: { name: 'My Studies', id: 'my-studies',tabName:`My ${Datasets["prop2"]}` },
  organization: { name: 'Organization', id: 'organization',tabName:`${Datasets["prop2"]}` },
  openData: { name: 'Open Data', id: 'open-data',tabName:'Public Data Sets'  },
};

const Environments = {
  "prop1": "Workspaces", // first word capitalized & plural | Ex: Workspaces
  "prop2": "Workspace", // first word capitalized & singular | Ex: Workspace
  "prop3": "workspace", // all words lower case & singular | Ex: workspace
  "prop4": "workspaces", // all works lower case & plural | Ex: workspaces
  "prop5": "Workspaces", // all words capitalized & plural | Ex: Workspaces
  "prop6": "Workspace", // all words capitalized & singular | Ex: Workspace
  "prop7": "/workspaces" // used for URL
};

export { Datasets, Workspaces, Environments };
