/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

// import React from 'react';
// import { Bar } from 'react-chartjs-2';

// import { barOptions } from './graph-options';

import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [771,457,550,980,663,427],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)'
    }
  ],
};


class LineGraph extends React.Component {
  
    chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chart.js Line Chart',
        },
      },
      scaleOverride : true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                return `$${value}`;
              }
            }
          }
        ]
      },
    };
    lineData ={
      
    }
    
    constructor(props) {
        super(props);
        this.lineData.labels = this.props.labels;
        this.lineData.datasets =  [{
              label: 'Monthly Cost',
              data:this.props.data,
              borderColor: '#861f41',
              fill: false,
              tension: 0.2,
            }];
        //this.chartOptions['labels'] = this.props.labels;
        //this.chartOptions['datasets'][0]['data'] = this.props.data;
        
    }
    
    componentDidMount(){
        
    }
    
    
  

render() {
   // const data = this.props.data;
    return <Line options={this.chartOptions} data={this.lineData} />;
    
  }
  
}


export default LineGraph;