/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { observable, runInAction, decorate } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Table, Segment, Header, Icon } from 'semantic-ui-react';
import { deleteStudyFile } from '../../helpers/api';
import { formatBytes, swallowError } from '@amzn/base-ui/dist/helpers/utils';
import { isStoreError, isStoreLoading, isStoreEmpty, stopHeartbeat } from '@amzn/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';
import { Checkbox } from 'semantic-ui-react';
import { displayError, displaySuccess } from '@amzn/base-ui/dist/helpers/notification';
import DeleteSingleStudyFile from './DeleteSingleStudyFile';

// expected props
// - study
class StudyFilesTable extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.filesStore = props.study.getFilesStore();
      this.permissionsStore = props.study.getPermissionsStore();
      this.currUser = props.userStore.user;
      this.modalOpen = false;
    });
    this.state ={
      isSelectAll :false,
      selectedFiles :null
    };
  }

  componentDidMount() {
    swallowError(this.filesStore.load());
    this.filesStore.startHeartbeat();
  }

  componentWillUnmount() {
    stopHeartbeat(this.filesStore);
  }

  render() {
    const store = this.filesStore;
    // Render loading, error, or tab content
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="m0" />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else if (isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else {
      content = this.renderTable();
    }

    return content;
  }
  
  
  
  onChangeSelectAll= (e, { checked }) => {
    const selectedFiles = this.state.selectedFiles | {};
    this.setState({isSelectAll:checked});
    const fileNames =  checked?this.filesStore.files.map(file => file.filename):Object.keys(selectedFiles);
    
    if(!checked){
      this.setState({selectedFiles:null});
    }else{
      const selectedFiles = {};
      fileNames.forEach((v,i) => selectedFiles[v] = v);
      this.setState({selectedFiles:selectedFiles});
    }
    //console.log(fileNames)
    this.props.onFileSelectAll(checked,fileNames);
  }
  
  onSelectDownloadHandler = (fileName,e,{checked}) => {
    
   let selectedFiles =  this.state.selectedFiles;
   const isSelectAll = this.state.isSelectAll;
   
   if(this.state.selectedFiles ==null){
     selectedFiles ={};
   }
   
   if(selectedFiles[fileName]){
     delete selectedFiles[fileName];
   }else{
     selectedFiles[fileName] =fileName;
   }
   this.setState({selectedFiles:selectedFiles});
  const fileNames =  Object.keys(selectedFiles);//isSelectAll?this.filesStore.files.map(file => file.filename):Object.keys(selectedFiles);
  //console.log(fileNames);
  const download = fileNames.length >0 ;
  this.props.onFileSelectAll(download,fileNames);
  
  }
  
  onDeleteDataSetHandler = (fileName)=>{
    
    try{
      runInAction(async () => {
          
          const deleteFile = await deleteStudyFile(this.props.study.id, fileName);
          if (deleteFile) {
            displaySuccess(`${fileName} was deleted successfully`);
          }
        });
    }
    catch (err) {
      displayError(err);
    }
  }

  renderTable() {
    const isSelectAll = this.state.isSelectAll;
    const selectedFiles = this.state.selectedFiles;
    
    const isFileChecked = !isSelectAll && selectedFiles ==null ? false: true;
    const studyPermissions = this.permissionsStore.studyPermissions;
    let isStudyAdmin = false;
    if(studyPermissions) {
      isStudyAdmin = studyPermissions.isStudyAdmin(this.currUser.uid);
    }
    
    return (
      <>
        <Table striped>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell>
            <Checkbox label='Select All' onChange={this.onChangeSelectAll} checked={isSelectAll}/>
            </Table.HeaderCell>
              <Table.HeaderCell>Filename</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Last Modified</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.filesStore.files.map(file => {
              let  checked = isFileChecked;
              
              if(!isFileChecked){
                if(selectedFiles && selectedFiles[file.filename] ){
                  checked =true;
                }
              }else if (selectedFiles){
                if(selectedFiles[file.filename]){
                 checked =true;
                }else{
                   checked =false;
                }
              }
              //console.log(checked);
              return <Table.Row key={file.filename}>
               <Table.Cell>
               <Checkbox checked={checked} onChange={this.onSelectDownloadHandler.bind(this, file.filename)}/>
               </Table.Cell>
                <Table.Cell>{file.filename}</Table.Cell>
                <Table.Cell>{formatBytes(file.size)}</Table.Cell>
                <Table.Cell>{file.lastModified.toISOString()}</Table.Cell>
                <Table.Cell>
                {isStudyAdmin && (
                /*<button class="ui icon button" onClick={this.onDeleteDataSetHandler.bind(this,file.filename)}>
                <i aria-hidden="true" class="trash icon"></i>
                
                </button>*/
                <DeleteSingleStudyFile file={{filename:file.filename}} studyid={{studyid:this.props.study.id}}/>
                )}
                
                </Table.Cell>
                
              </Table.Row>
            })}
          </Table.Body>
        </Table>
      </>
    );
  }

  renderEmpty() {
    return (
      <Segment placeholder className="mt0">
        <Header icon className="color-grey">
          <Icon name="file outline" />
          No files
          <Header.Subheader>No files are uploaded yet for this study</Header.Subheader>
        </Header>
      </Segment>
    );
  }
}

decorate(StudyFilesTable, {
  filesStore: observable,
});

export default inject('userStore')(observer(StudyFilesTable));
