/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
/* eslint-disable max-classes-per-file */
import React from 'react';
import _ from 'lodash';
import { decorate, computed, runInAction } from 'mobx';
import { observer, inject, Observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Breadcrumb,
  Divider,
  Grid,
  Segment,
  Table,
  Header,
  Message,
  Popup,
  Label,
  Icon,
  Tab,
  Dropdown,
  Button
} from 'semantic-ui-react';
import TimeAgo from 'react-timeago';

import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';
import { swallowError } from '@amzn/base-ui/dist/helpers/utils';
import { isStoreLoading, isStoreError, isStoreReady } from '@amzn/base-ui/dist/models/BaseStore';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';
import ProgressPlaceHolder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

import By from '../helpers/By';
import ScEnvironmentButtons from './parts/ScEnvironmentButtons';
import ScEnvironmentCost from './parts/ScEnvironmentCost';
import ScEnvironmentTypeName from './parts/ScEnvironmentTypeName';
import ScEnvironmentCostTable from './parts/ScEnvironmentCostTable';
import Stores from '@amzn/base-ui/dist/models/Stores';
import { updateScEnvironmentStudies } from '../../helpers/api';
import { displayError, displaySuccess } from '@amzn/base-ui/dist/helpers/notification';
import { Datasets, Environments } from '../../models/resources/page-properties.js';

// This component is used with the TabPane to replace the default Segment wrapper since
// we don't want to display the border.
// eslint-disable-next-line react/prefer-stateless-function
class TabPaneWrapper extends React.Component {
  render() {
    return <>{this.props.children}</>;
  }
}

// expected props
// - scEnvironmentsStore (via injection)
class ScEnvironmentDetailPage extends React.Component {
  generateTimerInterval;
  constructor(props) {
    super(props);
    this.state={
      disableDataSet:true,
      envRunningTimer: '',
      selectedDatasets:[]
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const store = this.getEnvStore();
    if (store) {
      swallowError(store.load());
      store.startHeartbeat();
    }
  }
  
  componentWillUnmount() {
    const store = this.getEnvStore();
    if (store) {
      store.stopHeartbeat();
    }
  }

  get envsStore() {
    return this.props.scEnvironmentsStore;
  }
  
  getProjectsStore() {
    return this.props.projectsStore;
  }

  get instanceId() {
    return (this.props.match.params || {}).instanceId;
  }

  getEnvStore() {
    const envsStore = this.envsStore;
    const envId = this.instanceId;
    return envsStore.getScEnvironmentStore(envId);
  }

  getEnv() {
    const store = this.getEnvStore();
    if (!store) return {};
    if (!isStoreReady(store)) return {};
    return store.scEnvironment;
  }

  render() {
    const store = this.getEnvStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder />;
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3">
        {this.renderBreadcrumb()}
        {content}
      </Container>
    );
  }

  renderBreadcrumb() {
    const envId = this.instanceId;
    const goto = gotoFn(this);

    return (
      <Breadcrumb className="block mb3">
        <Breadcrumb.Section link onClick={() => goto('/workspaces')}>
          Research {Environments["prop1"]}
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>{Environments["prop2"]} # {envId}</Breadcrumb.Section>
      </Breadcrumb>
    );
  }

  renderMain() {
    const env = this.getEnv();
    const projectsStore = this.getProjectsStore();
    const projectStore = projectsStore.getProjectStore(env.projectId);
    //const isSensitive = projectStore.project.hasSensitiveData;
    let isArch = false;
    if(projectStore.project.isArchived){
      isArch = true;
    }

    return (
      <>
        {this.renderTitle(env)}
        {this.renderError(env)}
        <Divider className="mt1 mb1" />
        {this.renderButtons(env)}
        <Divider className="mt1" />
        {/*env.description || 'Not description for this workspace was provided.'*/}
        {isArch && (<div><Label size="small" color="red">This project is archived. No further research can be done using this {Environments["prop3"]}.</Label></div>)}
        <Grid columns={2} stackable className="mt2">
          <Grid.Row stretched>
            <Grid.Column width={12}>{this.renderDetailTable(env)}</Grid.Column>
          </Grid.Row>
        </Grid>
        {this.renderTabs(env)}
      </>
    );
  }
  
  handleWorkspaceTableUpdate(env, studies){
    try{
    
      runInAction(async () => {
        const updateWorkspace = await updateScEnvironmentStudies(env.id, studies);
        if(updateWorkspace) {
          console.log(updateWorkspace);
          displaySuccess(`Data Set Added to ${Environments["prop2"]} Successfully`);
        }
      });
    
    }
    catch (err) {
      displayError(err);
    }
  }

  handleDataSetUpdate =(e, { value }) => {
    this.setState({selectedDatasets:value});
  }
  
  renderDetailTable(env) {
    const disableDataSet = this.state.disableDataSet;
    const studyIds = _.get(env, 'studyIds', []);
    let date = new Date(env.updatedAt);
    //console.log("date", date);
    let dm = date.getMonth() + 1;
    
    
    const selectedDatasets = this.state.selectedDatasets.length>0 ? this.state.selectedDatasets: studyIds
    const studyIdsDropdown =studyIds.map(id=>{
      return {
        key:id,
        text:id,
        value:id
      }
    });
    const studyCount = _.size(studyIds);
    const renderRow = (key, value) => (
      <Table.Row>
        <Table.Cell width={5}>{key}</Table.Cell>
        <Table.Cell width={11} className="breakout">
          {value}
        </Table.Cell>
      </Table.Row>
    );

    return (
      <Table definition>
        <Table.Body>
          {renderRow('Status', this.renderStatus(env))}
          {renderRow('Created By', <By uid={env.createdBy} skipPrefix />)}
          {renderRow(`${Datasets["prop2"]}`, studyCount === 0 ? `No ${Datasets["prop6"]} linked to this ${Environments["prop3"]}` : studyIds.join(', '))}
          
          {/*renderRow('Data Sets', studyCount === 0 ? 'No data sets linked to this workspace' : 
          <div className="flex">
            <Dropdown
              options={studyIdsDropdown}
              defaultValue={studyIds}
              fluid
              multiple
              selection
              search
              disabled={disableDataSet}
              onChange={this.handleDataSetUpdate}
            />
            {disableDataSet && <Button style={
              {
                margin:'0px 10px'
              }
            } icon onClick={(event)=>{
              this.setState({disableDataSet:!disableDataSet});
            }}>
              <Icon name='edit' />
            </Button>}
            {!disableDataSet && <Button style={
              {
                margin:'0px 10px'
              }
            } icon onClick={(event)=>{
              this.setState({disableDataSet:!disableDataSet});
              this.handleWorkspaceTableUpdate(env, selectedDatasets);
            }}>
              <Icon name='save' />
            </Button>}
          </div>
          )*/}
          
          {renderRow('Project', _.isEmpty(env.projectId) ? 'N/A' : env.projectId)}
          {renderRow('Last state Changed',  `${date.getFullYear()}-${dm}- ${date.getDate()}`)}
          {renderRow(`${Environments["prop2"]} Type`, <ScEnvironmentTypeName envTypeId={env.envTypeId} />)}
        </Table.Body>
      </Table>
    );
  }

  renderButtons(env) {
    return <ScEnvironmentButtons scEnvironment={env} />;
  }

  renderTitle(env) {
    const projectsStore = this.getProjectsStore();
    const projectStore = projectsStore.getProjectStore(env.projectId);
    const isSensitive = projectStore.project.hasSensitiveData || false;
    // this.generateTimer(env);
    return (
      <Header as="h3" className="mt1">
        <Icon name="server" className="align-top" />
        <Header.Content className="left-align">{env.name} {isSensitive && <Label size="medium" color="red"><Icon name='exclamation triangle' inverted/>Sensitive Project</Label>}</Header.Content>
        <Header.Subheader>
          <span className="fs-8 color-grey">
            Created <TimeAgo date={env.createdAt} className="mr2" /> <By uid={env.createdBy} className="mr2" />
          </span>
          <span className="fs-8 color-grey mr2"> {env.id}</span>
          {/*<span className="fs-8 color-grey mr1">
            Time Workspace being running : 
          </span>
          <b className="color-grey mr2">
            {this.state.envRunningTimer}
          </b>*/}
        </Header.Subheader>
        <Header.Subheader>
          <span className="fs-8 color-grey mr2">Description: {env.description}</span>
        </Header.Subheader>
      </Header>
    );
  }

  renderStatus(env) {
    const state = env.state;
    return (
      <div style={{ cursor: 'default' }}>
        <Popup
          trigger={
            <Label size="mini" color={state.color}>
              {state.spinner && <Icon name="spinner" loading />}
              {state.display}
            </Label>
          }
        >
          {state.tip}
        </Popup>
      </div>
    );
  }

  renderError(env) {
    if (_.isEmpty(env.error)) return null;

    return (
      <Message negative>
        <p>{env.error}</p>
      </Message>
    );
  }

  renderTabs(env) {
    const panes = [
      {
        menuItem: 'Cost',
        render: () => (
          <Tab.Pane attached={false} key="cost" as={TabPaneWrapper}>
            <Observer>{() => <ScEnvironmentCostTable envId={env.id} />}</Observer>
          </Tab.Pane>
        ),
      },
  //     {
  //       menuItem: 'CloudFormation Output',
  //       render: () => (
  //         <Tab.Pane attached={false} key="cfn-outputs" as={TabPaneWrapper}>
  //           <Observer>{() => this.renderCfnOutput(env)}</Observer>
  //         </Tab.Pane>
  //       ),
  //     },
    ];

     return <Tab className="mt4" menu={{ secondary: true, pointing: true }} renderActiveOnly panes={panes} />;
   }

  // renderCfnOutput(env) {
  //   const outputs = env.outputs;
  //   const isEmpty = _.isEmpty(outputs);
  //   const renderRow = (index, key, value, desc) => (
  //     <Table.Row key={index}>
  //       <Table.Cell width={5}>{key}</Table.Cell>
  //       <Table.Cell width={11} className="breakout">
  //         {value}
  //         <div className="fs-7">{desc}</div>
  //       </Table.Cell>
  //     </Table.Row>
  //   );

  //   return (
  //     <>
  //       {!isEmpty && (
  //         <Table definition className="mt3">
  //           <Table.Body>
  //             {_.map(outputs, (item, index) => renderRow(index, item.OutputKey, item.OutputValue, item.Description))}
  //           </Table.Body>
  //         </Table>
  //       )}
  //       {isEmpty && <Message className="mt3" content="None is available" />}
  //     </>
  //   );
  // }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ScEnvironmentDetailPage, {
  instanceId: computed,
  envsStore: computed,
});

export default inject('scEnvironmentsStore', 'projectsStore')(withRouter(observer(ScEnvironmentDetailPage)));
