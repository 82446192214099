/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
//import { Icon, Label, Menu, Table } from 'semantic-ui-react';
import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';
import { decorate, observable,computed } from 'mobx';
import { Header } from 'semantic-ui-react';
//import _ from 'lodash';
//import { displayError } from '@amzn/base-ui/dist/helpers/notification';
import { createLink } from '@amzn/base-ui/dist/helpers/routing';
//import validate from '@amzn/base-ui/dist/models/forms/Validate';
//import { getAddProjectForm, getAddProjectFormFields } from '../../models/forms/AddProjectForm';
import { Breadcrumb } from 'semantic-ui-react';
import PieGraph from '../dashboard/graphs/pieGraph';

import LineGraph from '../dashboard/graphs/lineGraph';
//import { BrowserRouter as Router, useParams } from "react-router-dom";
//import { isStoreLoading, isStoreError, isStoreReady, isStoreEmpty, stopHeartbeat } from '@amzn/base-ui/dist/models/BaseStore';
//import { swallowError } from '@amzn/base-ui/dist/helpers/utils';
import { stage } from '@amzn/base-ui/dist/helpers/settings';
import { Datasets, Environments } from '../../models/resources/page-properties.js';

class AdminProjectDashboard extends React.Component {
   
  constructor(props) {
    super(props);
  }
  
  // getUsersStore and getTotalUsers are used to get the total users count
  getUsersStore() {
    return this.props.usersStore;
  }
  
  getTotalUsers() {
    const store = this.getUsersStore();
    const usersList = store.list;
    const totalUsers = usersList.length;
    return totalUsers;
  }
  
  // getenvsStore and getTotalEnvs are used to get the total workspaces count
  getenvsStore() {
    const store = this.props.scEnvironmentsStore;
    store.doLoad();
    return store;
  }
  
  // getTotalEnvs() {
  //   //const estore = this.getenvsStore();
  //   const estore =  this.props.scEnvironmentsStore;
  //   const eList = estore.list;
  //   //const totalEnvs = envList.length;
  //   let totalEnvs = _.filter(eList, function(en) {
  //       return en.status != 'TERMINATED';
  //     });
  //   totalEnvs = totalEnvs.length;
  //   console.log("totalEnvs",totalEnvs );
  //   return totalEnvs;
  // }
  
  getEnvTileData(){
    const envStore =  this.props.scEnvironmentsStore;
    //const envStore = this.getenvsStore();
    envStore.doLoad();
    const envs = envStore.list;
    let tileData = [];
    
    if(envs && envs.length>0){
      let failed = _.filter(envs, function(env) {
        return env.status === 'FAILED';
      });
      
      let stopped = _.filter(envs, function(env) {
        return env.status === 'STOPPED';
      });
      
      let completed = _.filter(envs, function(env) {
        return env.status === 'COMPLETED';
      });
    
      tileData[0] = failed.length;  
      tileData[1] = stopped.length;
      tileData[2] = completed.length;
    }
    return tileData;
  }
  
  // getAwsAccountsStore and getAwsAccounts are used to get the total AWS Accounts count
  getAwsAccountsStore() {
    const store = this.props.awsAccountsStore;
    return store;
  }

  getAwsAccounts() {
    const store = this.getAwsAccountsStore();
    const accountsList = store.list;
    const totalAccounts = accountsList.length;
    return totalAccounts;
    
  }
  
  // getIndexesStore and getIndexes are used to get the total indexes count
  getIndexesStore() {
    const store = this.props.indexesStore;
    store.load();
    return store;
  }

  getIndexes() {
    const store = this.getIndexesStore();
    const indexList = store.list;
    const totalIndexes = indexList.length;
    return totalIndexes;
  }
  
  getKeyPairs() {
    const store = this.props.keyPairsStore;
    store.setTotalKP();
    const tcc = store.totalKP;
    return tcc;
  }
  
  getTotalDataSets() {
    const dataStore = this.props.studiesStoresMap;
    dataStore['my-studies'].setTotalStudies();
    const totalDataSets = dataStore['my-studies'].totalStudies;
    return totalDataSets;
  }

  goto(pathname) {
    const location = this.props.location;
    const link = createLink({ location, pathname });
    this.props.history.push(link);
  }
  
  renderBreadcrumb() {
    const goto = gotoFn(this);

    return (
      <Breadcrumb className="block mb3">
        <Breadcrumb.Section link onClick={() => goto('/')} active>
          Home
        </Breadcrumb.Section>
        
      </Breadcrumb>
    );
  }
  
  getcostsStore() {
    const store =  this.props.scEnvironmentCostsStore;
    //store.doLoad();
    return store;
  }
  
   getTotalCost(){
    const costStore =  this.getcostsStore();
    //console.log("costStore", costStore);
    const store =  this.props.scEnvironmentCostsStore;
    const cost = store.setTotalCost();
    
    return store.totalEnvCost;
  }
  
  getTotalCostByMonth(){
    
    const store =  this.props.scEnvironmentCostsStore;
     store.setTotalEnvCostByMonth();
     return store;
  }
  
  getProjectsTileData(){
    const projectStore =  this.props.projectsStore;
    const projects = projectStore.list;
    let tileData = [0,0];
    
    if(projects && projects.length>0){
    let archivedProject = _.filter(projects, function(pjt) {
                               return pjt.isArchived;
                            });
          tileData[0] =  archivedProject.length;  
          tileData[1] =  projects.length - archivedProject.length;
    }
    
    return tileData;
  }
  
  getProjectsTotal() {
    const projectStore =  this.props.projectsStore;
    const projects = projectStore.list;
    const total = projects.length;
    return total;
  }

  render() {
    const userCount = this.getTotalUsers();
    const accountsCount = this.getAwsAccounts();
    const indexCount = this.getIndexes();
    const keyCount = this.getKeyPairs();
    const dataSetCount = this.getTotalDataSets();
    const projectUsersLabel =['Archived', 'Active Projects'];
    let projectUsersData = this.getProjectsTileData(); //[10,10];
    const projTotal = this.getProjectsTotal();
    const envUsersLabel =['Failed', 'Stopped', 'Started'];
    let envData = this.getEnvTileData();
    let envCount = envData.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
   // const  costsStore = this.getcostsStore().doLoad();
    const totalCost = this.getTotalCost().toFixed(2);
    const store  = this.getTotalCostByMonth();
    const totalCostByMonthLabels= JSON.parse(JSON.stringify(store.totalCostByMonthLabels));//toJS(store.totalCostByMonthLabels)//.slice() || [];
    const totalCostByMonthData=  JSON.parse(JSON.stringify(store.totalCostByMonthData));//;toJS(store.totalCostByMonthData)//.slice() || [];
    
    var user = this.props.userStore.user;
   if (!user.isAdmin){
          return null;
   }
    //console.log(totalCostByMonthLabels)
    //console.log(totalCostByMonthData)
   
    
    return (
      <div className="mt2 animated fadeIn">
      {this.renderBreadcrumb()}
        <Header as="h2" icon textAlign="center" className="mt3" color="grey">
         Administrator Dashboard
        </Header>
       {user.isAdmin && <div className="mt3 ml3 mr3 animated fadeIn">
        <div className="vt-flex vt-flex-wrap">
          <div className="vt-w-100 vt-flex vt-tile-row">
            <div class="vt-tile-sm vt-tile-lg primary vt-tile-chart  vt-flex-grow-1">
                <div class="header">
                   <PieGraph labels={projectUsersLabel} data={projectUsersData}></PieGraph>
                </div>
                <div class="title">Total Projects - {projTotal}</div>
            </div>
            
            
            <div class="vt-tile-sm vt-tile-lg  vt-flex-grow-1">
                <div class="header">
                   ${totalCost}
                </div>
                <div class="title">Total Compute Cost Last 30 days</div>
            </div>
          </div>
          
          <div className="vt-w-100 vt-flex vt-tile-row">
         <div class="vt-tile-sm vt-tile-lg primary vt-tile-chart  vt-flex-grow-1 linechart">
                <div class="header linechart-header">
                    {totalCostByMonthLabels.length && <LineGraph labels={totalCostByMonthLabels} data={totalCostByMonthData}></LineGraph>}
                </div>
                <div class="title">Monthly Costs</div>
            </div>
          </div>
          
          <div className="vt-w-100 vt-flex vt-tile-row">
            <div class="vt-tile-sm primary vt-tile-chart vt-flex-grow-1">
                  <div class="header">
                      {envData.length && <PieGraph labels={envUsersLabel} data={envData}></PieGraph>}
                      
                  </div>
                  <div class="title">Total Active {Environments["prop1"]} - {envCount}</div>
              </div>
             <div class="vt-tile-sm secondary vt-flex-grow-1">
                <div class="header">
                    {dataSetCount}
                </div>
                <div class="title">Total {Datasets["prop2"]}</div>
            </div>
             <div class="vt-tile-sm primary  vt-flex-grow-1">
                <div class="header">
                    {keyCount}
                </div>
                <div class="title">Total SSH Keys</div>
            </div>
          </div>
          
          <div className="vt-w-100 vt-flex vt-tile-row">
             <div class="vt-tile-sm vt-flex-grow-1">
                <div class="header">
                    {accountsCount}
                </div>
                <div class="title">Total AWS Accounts</div>
            </div>
             <div class="vt-tile-sm secondary vt-flex-grow-1">
                <div class="header">
                    {userCount}
                </div>
                <div class="title">Total Users (Accessed VTSURE)</div>
              </div>
              <div class="vt-tile-sm secondary vt-flex-grow-1">
                <div class="header">
                    {indexCount}
                </div>
                <div class="title">Total Indexes</div>
              </div>
          </div>
          
          
        </div>
        </div>
       }
      </div>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(AdminProjectDashboard, {
  user: observable,
  costsStore: computed,
});
export default inject('usersStore','userStore', 'indexesStore', 'projectsStore', 'scEnvironmentsStore','studiesStoresMap','scEnvironmentCostsStore', 'scEnvironmentsStore', 'awsAccountsStore', 'keyPairsStore')(withRouter(observer(AdminProjectDashboard)));
