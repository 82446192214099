/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { decorate, action, computed, runInAction, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Header, Checkbox, Segment, Accordion, Icon, Popup, Label, Button } from 'semantic-ui-react';
import c from 'classnames';
import { disableStudyUploadByResearcher } from '../../helpers/settings';
import { swallowError } from '@amzn/base-ui/dist/helpers/utils';
import { isStoreError, isStoreLoading, isStoreNew, stopHeartbeat } from '@amzn/base-ui/dist/models/BaseStore';
import { getPresignedStudyDownloadRequests } from '../../helpers/api';
import { displayError, displaySuccess } from '@amzn/base-ui/dist/helpers/notification';
import StudyFilesTable from './StudyFilesTable';
import StudyPermissionsTable from './StudyPermissionsTable';
import UploadStudyFiles from './UploadStudyFiles';
import DeleteStudyFiles from './DeleteStudyFiles';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

// expected props
// - study (via props)
// - isSelectable (via props)
// - filesSelection (via injection)
class StudyRow extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.filesExpanded = false;
      this.permissionsExpanded = false;
      this.permissionsStore = props.study.getPermissionsStore();
      this.currUser = props.userStore.user;
    });
    
    this.state ={
      isFilesSelectAll:false,
      selectedFilesList:[],
      blocking:false
    }
  }
  
  get instanceId() {
    return (this.props.match.params || {}).instanceId;
  }
  
  getUserDisplayName() {
    return this.props.userDisplayName;
  }

  get getUserRole() {
    return this.props.userRole;
  }

  get study() {
    return this.props.study;
  }

  get isSelectable() {
    return this.props.isSelectable && this.study.state.canSelect;
  }
  
  componentDidMount() {
    swallowError(this.permissionsStore.load());
    this.permissionsStore.startHeartbeat();
  }

  componentWillUnmount() {
    stopHeartbeat(this.permissionsStore);
  }

  handleFileSelection = study => {
    const selection = this.props.filesSelection;
    if (selection.hasFile(study.id)) {
      selection.deleteFile(study.id);
    } else {
      const { id, name, description } = study;
      // TODO: actually do different statuses?
      selection.setFile({ id, name, description, accessStatus: 'approved' });
    }
  };

  handleFilesExpanded = () => {
    this.filesExpanded = !this.filesExpanded;
  };

  handlePermissionsExpanded = () => {
    this.permissionsExpanded = !this.permissionsExpanded;
  };
  
  generateZipFromCloud = (urls,fileNames,workspaceId) => {
    
      const date = new Date();
      //add one to zero-based Month value
      let filename = `${workspaceId}_${date.getMonth() + 1}${date.getDate()}${date.getFullYear()}${date.getTime()}`;
      
     
      
      runInAction(async () => {
         const zip = new JSZip();
       // const folder = zip.folder('project');
        const urlsLength = urls.length;
        let counter = 0;
        const filesList = await urls.map(async (url,index) => {
            
            const s3fetchURL = await fetch(url).then((response)=>{
              return response;
            })
            
            console.log(s3fetchURL)
            const fileUrl = [s3fetchURL.url];//Object.values(s3fetchURL);
            const fileKeys = ['data']//Object.keys(s3fetchURL);
            
            if(fileUrl && fileUrl.length){
              
            const s3FileRes = await fetch(fileUrl[0])
            .then(response=>{
              counter = counter+1;
              if (response.status === 200 || response.status === 0) {
                
                  const data = response.blob();//await s3FileRes.blob();
            
                  console.log(s3FileRes);
                  //folder.file(fileKeys[0], data);
                  zip.file(fileNames[index], data); 
                  
                  
                  if(counter == urlsLength){
                    console.log("blob",filename);
                    zip.generateAsync({type:"blob"})
                    .then(blob => {
                      
                      saveAs(blob, filename);
                       this.setState({blocking:false});
                       //displaySuccess('Selected files were downloaded successfully');
                      
                    })
                    .catch(e => console.log(e));
                  }
            
                    //return Promise.resolve(response.blob());
                } else {
                  this.setState({blocking:false});
                    return Promise.reject(new Error(response.statusText));
                }
            });
           
            
            
            return s3FileRes;
            }
        })
        
       
        
        });
        
  }

  render() {
    const isSelectable = this.isSelectable; // Internal and external guests can't select studies
    const study = this.study;
    const selection = this.props.filesSelection;
    const isSelected = selection.hasFile(study.id);
    const attrs = {};
    const onClickAttr = {};

    if (isSelected) attrs.color = 'blue';
    if (isSelectable) onClickAttr.onClick = () => this.handleFileSelection(study);

    return (
      <Segment clearing padded raised className="mb3" {...attrs}>
        <div data-testid="study-card" className="flex">
          <div className="mr2" {...onClickAttr}>
            {isSelectable && <Checkbox checked={isSelected} style={{ marginTop: '17px' }} />}
          </div>
          <div className="flex-auto mb1">
            {this.renderStatus(study.state)}
            {this.renderHeader(study)}
            {this.renderFilesAccordion(study)}
            {this.renderPermissionsAccordion(study)}
          </div>
        </div>
      </Segment>
    );
  }
  //{this.renderDescription(study)}

  handleSelectAll(checked,data){
    this.setState({isFilesSelectAll:checked,selectedFilesList:data});
   // this.
  }
  
  onDownloadHandler(study){
    const files = this.state.selectedFilesList;
    this.setState({blocking:true});
        try{
            
            runInAction(async () => {
                const fileNames = await getPresignedStudyDownloadRequests(study.id, files);
                console.log(fileNames);
                console.log( Object.values(fileNames));
               
                this.generateZipFromCloud( Object.values(fileNames),Object.keys(fileNames), study.id)
                // const map = this.props.studiesStoresMap;
                // map['my-studies'].load();
                // map['organization'].load();
                
            });
        }
        catch (err) {
            displayError(err);
        }
  }
  
  renderHeader(study) {
    // Disable the upload files button for the researcher. This feature would be enable based on
    // the flag "disableStudyUploadByResearcher" is set to true.
    const disableResearcherAccess = this.getUserRole === 'researcher' && disableStudyUploadByResearcher === true;
    const studyPermissions = this.permissionsStore.studyPermissions;
    let isStudyAdmin = false;
    if(studyPermissions) {
      isStudyAdmin = studyPermissions.isStudyAdmin(this.currUser.uid);
    }
    const isSelectable = this.isSelectable; // Internal and external guests can't select studies
    const onClickAttr = {};
    let hasProjectId = true;
    if (!study.projectId) {
      hasProjectId = false;
    }
    let hasCreator = true;
    if(study.createdBy === '_system_') {
      hasCreator = false;
    }
    let notOpenData = true;
    if(study.category === 'Open Data'){
      notOpenData = false;
    }
    let createdDate = study.createdAt;
    createdDate = createdDate.split('T')[0];
    if (isSelectable) onClickAttr.onClick = () => this.handleFileSelection(study);
    
    const userDisplayName = this.getUserDisplayName();
    const prjStore =  this.props.projectsStore;
    let isSensitive = false;
    console.log("Current User", this.currUser);
    const isAdmin = this.currUser.isAdmin;
    console.log("IsAdmin", isAdmin);
    if(study.category !== 'Open Data') {
      const project =  prjStore.getProject(study.projectId);
      const isProjectAdmin = project.projectAdmins.includes(this.currUser.uid);
      isSensitive = project.hasSensitiveData || false;
    }
    const isFilesSelectAll = this.state.isFilesSelectAll;

    return (
      <div>
      <BlockUi tag="div" blocking={this.state.blocking} className="my-2">
        <Header as="h3" color="blue" className={c('mt2', isSelectable ? 'cursor-pointer' : '')} {...onClickAttr}>
        
        {isFilesSelectAll &&  isAdmin && <Button
        floated="right"
        color="blue"
        basic
        onClick={this.onDownloadHandler.bind(this,study)}
      >
        Download Selected Files
      </Button>}
          
          {study.uploadLocationEnabled && study.canUpload && !disableResearcherAccess && (
            <UploadStudyFiles studyId={study.id} />
          )}
          {study.uploadLocationEnabled && study.canUpload && !disableResearcherAccess && isStudyAdmin && (
            <DeleteStudyFiles studyId={study.id} />
          )}
          {study.name} &middot; ID: {study.id} {notOpenData && isSensitive && <Label size="medium" color="red"><Icon name='exclamation triangle' inverted/>Sensitive Project</Label>}
          <Header.Subheader>
            <div>{study.description}</div>
            {hasCreator &&
            <div><b>Created By:</b> {userDisplayName.getDisplayName({ uid: study.createdBy })}</div>
            }
            {notOpenData &&
            <div><b>Created Date:</b> {createdDate}</div>
            }
            {hasProjectId &&
            <div><b>Project ID:</b> {study.projectId}</div>
            }
          </Header.Subheader>
        </Header>
        </BlockUi>
      </div>
    );
  }

  renderDescription(study) {
    return <div><span className="pt1 fs-8 color-grey"><b>Data Set Description:</b> {study.description}</span></div>;
  }

  renderStatus(state) {
    // Do not show a label if it is default/reachable
    if (state && (state.key === 'default' || state.key === 'reachable')) return null;

    return (
      <div style={{ cursor: 'default' }}>
        <Popup
          trigger={
            <Label attached="top left" size="mini" color={state.color}>
              {state.spinner && <Icon name="spinner" loading />}
              {state.display}
            </Label>
          }
        >
          {state.tip}
        </Popup>
      </div>
    );
  }

  renderFilesAccordion(study) {
    if (study.isOpenDataStudy) return null;
    if (!study.uploadLocationEnabled) return null;
    const expanded = this.filesExpanded;

    return (
      <Accordion className="mt2">
        <Accordion.Title active={expanded} index={0} onClick={this.handleFilesExpanded}>
          <Icon name="dropdown" />
          <b>Files</b>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          {expanded && study.uploadLocationEnabled && (
            <div className="mb2">
              <StudyFilesTable study={study} onFileSelectAll={this.handleSelectAll.bind(this)}/>
            </div>
          )}
        </Accordion.Content>
      </Accordion>
    );
  }

  renderPermissionsAccordion(study) {
    if (!study.isOrganizationStudy) return null;
    const expanded = this.permissionsExpanded;

    return (
      <Accordion className="mt0">
        <Accordion.Title active={expanded} index={0} onClick={this.handlePermissionsExpanded}>
          <Icon name="dropdown" />
          <b>Permissions</b>
        </Accordion.Title>
        <Accordion.Content active={expanded}>{expanded && <StudyPermissionsTable study={study} />}</Accordion.Content>
      </Accordion>
    );
  }
}

decorate(StudyRow, {
  handleFileSelection: action,
  handleFilesExpanded: action,
  handlePermissionsExpanded: action,
  study: computed,
  filesExpanded: observable,
  permissionsExpanded: observable,
  isSelectable: computed,
  instanceId: computed
});

export default inject('filesSelection', 'userDisplayName', 'userStore', 'projectsStore')(observer(StudyRow));
