/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { observer } from 'mobx-react';
import { decorate, observable, action, runInAction, computed } from 'mobx';
import { Button, Dimmer, Header, Loader, Table, Label, List, Dropdown, Segment, Modal, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';
import { displayError, displaySuccess,displayWarning } from '@amzn/base-ui/dist/helpers/notification';
import Stores from '@amzn/base-ui/dist/models/Stores';
import { swallowError } from '@amzn/base-ui/dist/helpers/utils';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';
import BasicProgressPlaceholder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import validate from '@amzn/base-ui/dist/models/forms/Validate';

import { createLink } from '@amzn/base-ui/dist/helpers/routing';
import { getAddProjectFormFields, getAddProjectForm } from '../../models/forms/AddProjectForm';


import { getUsers } from '@amzn/base-ui/dist/helpers/api';

class ProjectConfigure extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.stores = new Stores([this.usersStore, this.awsAccountsStore, this.projectsStore, this.userStore]);
      //console.log("stores", this.stores);
      //console.log("usersStore", this.usersStore);
      const { rev, id, description, indexId, projectAdmins,projectResearchers,projectViewers,isArchived,hasSensitiveData, complianceCategories, proposalID, fundNumber, IRBprotocolNumber, departmentID} = this.props.project;
      this.updateProject = {
        rev,
        id,
        description,
        indexId,
        projectAdmins,
        projectResearchers,
        projectViewers,
        isArchived,
        hasSensitiveData,
        complianceCategories,
        proposalID,
        fundNumber,
        IRBprotocolNumber,
        departmentID
      };
      this.formProcessing = false;
      this.modalOpen = false;
      this.view = 'detail';
    });
    this.form = getAddProjectForm();
    this.addProjectFormFields = getAddProjectFormFields();
    this.currentProject = this.props.project;
    
    this.state ={
      projecteUsers :[],
      selectedUsers:[],
      ProjectResearchers:[],
      ProjectViewers:[],
      ProjectAdmins:[],
      proposalID: '',
      fundNumber: '',
      IRBprotocolNumber: '',
      departmentID: ''
    };
  }

  ProjectResearchers =[];
  componentWillUnmount() {
    this.cleanUp();
  }

  getStores() {
    return this.stores;
  }

  componentDidMount() {
    swallowError(this.getStores().load());
  }

  cleanUp() {
    runInAction(() => {
      this.modalOpen = false;
    });
  }

  handleModalOpen = () => {
    runInAction(() => {
      const { rev, id, description, indexId, projectAdmins,projectResearchers,projectViewers,isArchived,hasSensitiveData, complianceCategories, proposalID, fundNumber, IRBprotocolNumber, departmentID} = this.props.project;
      this.updateProject = {
        rev,
        id,
        description,
        indexId,
        projectAdmins,
        projectResearchers,
        projectViewers,
        isArchived,
        hasSensitiveData,
        complianceCategories,
        proposalID,
        fundNumber,
        IRBprotocolNumber,
        departmentID
      };
      this.formProcessing = false;
      this.modalOpen = false;
      this.view = 'detail';
      this.modalOpen = true;
      this.setProjectResearchers();
      this.setProjectViewers();
      this.setProjectAdmins();
    });
  };

  handleModalClose = () => {
    this.cleanUp();
  };
  
  goto(pathname) {
    const location = this.props.location;
    const link = createLink({ location, pathname });
    this.props.history.push(link);
  }
  
  getUserStore() {
    const userStore = this.props.userStore;
    userStore.load();
    return userStore;
  }
  
  getUser() {
    const userStore = this.getUserStore();
    return userStore.user;
  }

  renderDetailView() {
    const users = this.props.usersStore;
    const project =   this.props.project;
    //console.log("usersStoreInDetailView", userS);
    const getFieldLabel = fieldName => this.form.$(fieldName).label;
    const toRow = (fieldName,isCheckbox,isChecked) => {
      //console.log("Field name", fieldName);
      //onsole.log("FieldNameType", typeof fieldName);
      let value = _.get(this.currentProject, fieldName);
      //console.log("Value", value);
      if(_.isString(value)) {
        if(value.startsWith("u-")) {
          let usr = this.usersStore.asUserObject({ uid: value });
          value = usr.firstName;
        }
      }
      let displayValue = _.isArray(value)
        ? _.map(value, (v, k) => {
          if(fieldName === 'complianceCategories'){
            //console.log("InsideCode");
            return <Label key={k} content={v} />;
          }
          else{
            const user = this.usersStore.asUserObject({ uid: v });
            let usr;
            if(/\s/.test(user.firstName)) {
              usr = user.firstName;
            }
            else {
              usr = user.firstName + " " + user.lastName;
            }
            //.log("userStoreMap", user);
            return <Label key={k} content={usr} />;
          }})
        : value;
        
      
      return (
        <>
          <Table.Cell collapsing active>
            {getFieldLabel(fieldName)}
          </Table.Cell>
          <Table.Cell>
            {isCheckbox?<Checkbox  disabled checked={isChecked}/>:displayValue}
          </Table.Cell>
        </>
      );
    };

    return (
      <Segment basic className="ui fluid form mb4">
        <Table celled>
          <Table.Body>
            <Table.Row>{toRow('id')}</Table.Row>
            <Table.Row>{toRow('description')}</Table.Row>
            <Table.Row>{toRow('createdBy')}</Table.Row>
            <Table.Row>{toRow('projectAdmins')}</Table.Row>
            <Table.Row>{toRow('projectResearchers')}</Table.Row>
            <Table.Row>{toRow('projectViewers')}</Table.Row>
            <Table.Row>{toRow('hasSensitiveData',true,this.updateProject.hasSensitiveData)}</Table.Row>
            <Table.Row>{toRow('complianceCategories')}</Table.Row>
            <Table.Row>{toRow('proposalID')}</Table.Row>
            <Table.Row>{toRow('fundNumber')}</Table.Row>
            <Table.Row>{toRow('IRBprotocolNumber')}</Table.Row>
            <Table.Row>{toRow('departmentID')}</Table.Row>
            <Table.Row>{toRow('isArchived',true,this.updateProject.isArchived)}</Table.Row>
          </Table.Body>
        </Table>
        {this.renderButtons()}
      </Segment>
    );
  }

  renderTrigger() {
    return (
      <Button size="mini" compact color="blue" onClick={this.handleModalOpen}>
        Details
      </Button>
    );
  }

  renderMain() {
    let content = null;
    if (this.view === 'detail') {
      content = this.renderDetailView();
    } else if (this.view === 'edit') {
      content = this.renderEditView();
    }
    return content;
  }

  render() {
    const stores = this.getStores();
    
    if(this.state.projecteUsers.length == 0){
      const initialProjectUsers = this.updateProjectUsers();
      
      this.state.projecteUsers =initialProjectUsers;
    }
    
    let content = null;
    if (stores.hasError) {
      content = <ErrorBox error={stores.error} className="p0 mb3" />;
    } else if (stores.loading) {
      content = <BasicProgressPlaceholder />;
    } else if (stores.ready) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={this.handleModalClose}>
        <div className="mt2 animated fadeIn">
          <Header as="h3" icon textAlign="center" className="mt3" color="grey">
            Project Detail
          </Header>
          <div className="mt3 ml3 mr3 animated fadeIn">{content}</div>
        </div>
      </Modal>
    );
  }
  
  

  renderEditView() {
    
    const processing = this.formProcessing;
    const fields = this.addProjectFormFields;
    const usersStore = this.props.usersStore;
    //console.log("usersStoreOnEditView", usersStore);
    
   
    this.props.usersStore.load();
    
    
    const toEditableInput = (attributeName, type = 'text',isDisabled=false) => {
      const handleChange = action(event => {
        event.preventDefault();
        this.updateProject[attributeName] = event.target.value;
      });
      return (
        <div className="ui focus input">
          <input
            type={type}
            defaultValue={this.currentProject[attributeName]}
            placeholder={fields[attributeName].placeholder || ''}
            onChange={handleChange}
            disabled={isDisabled}
            maxLength={fields[attributeName].max}
          />
        </div>
      );
    };
    //this.setProjectResearchers();

    return (
      <Segment basic className="ui fluid form">
        <Dimmer active={processing} inverted>
          <Loader inverted>Checking</Loader>
        </Dimmer>
        {this.renderField('id', toEditableInput('id', 'id',true))}
        <div className="mb2" />
        {this.renderField('description', toEditableInput('description', 'description',this.updateProject.isArchived))}
        <div className="mb2" />
        {this.renderField('projectAdmins')}
        {this.renderProjectAdminsSelection()}
        
         <div className="mb2" />
        {this.renderField('projectResearchers')}
        {this.renderProjectResearchersSelection()}
        
         <div className="mb2" />
        {this.renderField('projectViewers')}
        {this.renderProjectViewersSelection()}
        
        <div className="mb2" />
        {this.renderField('hasSensitiveData')}
        {this.renderHasSensitiveDataSelection()}
        
        <div className="mb2" />
        {this.renderComplianceCategories()}

        <div className="mb2" />
        {this.renderField('proposalID', toEditableInput('proposalID', 'proposalID'))}
        
        <div className="mb2" />
        {this.renderField('fundNumber', toEditableInput('fundNumber', 'fundNumber'))}
        
        <div className="mb2" />
        {this.renderField('IRBprotocolNumber', toEditableInput('IRBprotocolNumber', 'IRBprotocolNumber'))}
        
        <div className="mb2" />
        {this.renderField('departmentID', toEditableInput('departmentID', 'departmentID'))}
        
        <div className="mb2" />
        {this.renderField('isArchived')}
        {this.renderisArchivedSelection()}
        
        <div className="mb2" />
        {this.renderButtons()}
        {this.handleProjectAdminClose()}
        
        <div className="mb4" />
      </Segment>
    );
  }
  
  handleComplianceCategoriesSelection = (e, { value }) => {
    runInAction(() => {
      this.updateProject.complianceCategories = value;

    });
  };

  renderComplianceCategories() {
    const options = [
      { key: 'HIPAA', text: 'HIPAA', value: 'HIPAA' },
      { key: 'FERPA', text: 'FERPA', value: 'FERPA' },
      { key: 'GDPR', text: 'GDPR', value: 'GDPR' },
    ];
    
    const fields = this.addProjectFormFields;
    const placeholder = fields.complianceCategories.placeholder || 'Please select applicable compliance categories.';
    const explain = fields['complianceCategories'].explain;
    const label = fields['complianceCategories'].label;
    const hasExplain = !_.isEmpty(explain);
    
    //console.log("Current Project", this.currentProject);
    //const currentSelections = _.map(this.currentProject.complianceCategories, compid => projectStore.asUserObject({ uid }));
    const defaultValue = this.currentProject.complianceCategories;
    
    return (
      <div>
        <Header className="mr3 mt0" as="h4" color="grey">
          {label}
        </Header>
        {hasExplain && <div className="mb2">{explain}</div>}
        <Dropdown
          options={options}
          placeholder={placeholder}
          defaultValue={defaultValue}
          fluid
          /*disabled={!this.state.hasSensitiveData}*/
          className='compDD'
          multiple
          selection
          onChange={this.handleComplianceCategoriesSelection}
        />
      </div>
    );
  }

  handleClickEditButton = () => {
   // const { rev, id, description, indexId, projectAdmins,projectResearchers,projectViewers,isArchived} = this.props.project;
    const { rev, id, description, indexId, projectAdmins,projectResearchers,projectViewers,isArchived, hasSensitiveData, complianceCategories, proposalID, fundNumber, IRBprotocolNumber, departmentID} = this.props.project;
      // this.updateProject = {
      //   rev,
      //   id,
      //   description,
      //   indexId,
      //   projectAdmins,
      //   projectResearchers,
      //   projectViewers,
      //   isArchived
      // };
    runInAction(() => {
      this.view = 'edit';
      this.updateProject.projectAdmins = projectAdmins;
      this.updateProject.projectResearchers = projectResearchers;
      this.updateProject.projectViewers = projectViewers;
      this.updateProject.complianceCategories = complianceCategories;
      
      this.setProjectResearchers();
      this.setProjectAdmins();
      this.setProjectViewers();
      this.setComplianceCategories();
       
      // this.setProjectResearchers();
      // this.setProjectAdmins();
      // this.setProjectViewers();
    });
  };

  handleClickCancelButton = () => {
    if (this.view === 'edit') {
      runInAction(() => {
        this.updateProject.isArchived = this.currentProject.isArchived;
        this.updateProject.hasSensitiveData = this.currentProject.hasSensitiveData;
        // if (this.updateProject.isArchived === true) {
        //   this.updateProject.isArchived = true;
        // }
        // else if (this.updateProject.isArchived === false) {
        //   this.updateProject.isArchived = false;
        // }
        this.view = 'detail';
      });
    } else {
      this.handleModalClose();
    }
  };
  
  
  updateProjectUsers(){
    const projectAdmins = this.updateProject.projectAdmins;
    const projectResearchers = this.updateProject.projectResearchers;
    const projectViewers = this.updateProject.projectViewers;
    const preojcteUsersSet = [...projectAdmins,...projectResearchers,...projectViewers];
    
   
    
    const preojcteUsers = _.uniq(preojcteUsersSet);
    
    return preojcteUsers;
    
    /* this.state ={
      preojcteUsers:preojcteUsers
    }*/
    
   // const currentProjectAdminUsers = _.map(this.currentProject.projectAdmins, uid => usersStore.asUserObject({ uid }));
  }

  handleClickSubmitButton = action(async () => {
    if (!this.updateProject.id) {
      this.updateProject.id = this.currentProject.id;
    }
    runInAction(() => {
      this.formProcessing = true;
    });
    
    if(this.updateProject.proposalID == null) {
      this.updateProject.proposalID = 'None';
    }
    if(this.updateProject.fundNumber == null) {
      this.updateProject.fundNumber = 'None';
    }
    if(this.updateProject.IRBprotocolNumber == null) {
      this.updateProject.IRBprotocolNumber = 'None';
    }
    if(this.updateProject.departmentID == null) {
      this.updateProject.departmentID = 'None';
    }
    this.currentProject.description = this.updateProject.description;
    this.currentProject.complianceCategories = this.updateProject.complianceCategories;
    this.currentProject.proposalID = this.updateProject.proposalID;
    this.currentProject.fundNumber = this.updateProject.fundNumber;
    this.currentProject.IRBprotocolNumber = this.updateProject.IRBprotocolNumber;
    this.currentProject.departmentID = this.updateProject.departmentID;
    try {
        let canProcess = true;
        const currentAdminsAtSubmit = this.updateProject.projectAdmins;
        if (currentAdminsAtSubmit.length === 0) {
          canProcess = false;
          this.formProcessing = false;
          alert("There must be at least 1 Project Owner before submitting");
        }
        if (canProcess) {
          const store = this.getStore();
          //const usersStore = this.getUserStore();
          const usersStore = this.props.usersStore;
          //console.log("usersStoreBeforeLoad", usersStore);
          await store.updateProject(this.updateProject);
          const projectUsers =  this.updateProjectUsers();
          
          //usersStore = await usersStore.doLoad();
          await usersStore.doLoad();
          //console.log("usersStoreAfterLoad", usersStore);
          
         await  projectUsers.forEach(async (uid)=>{
            //console.log("id", uid);
            const user = usersStore.asUserObject({ uid });
            const user1 = this.getUser();
            //console.log("user", user);
            //console.log("user1", user1);
            
            if(user){
              let projectId = [...user.projectId];//currentUser.projectId;
              
              //console.log("User --> ",user);
              
              //console.log("Initial Project IDs for the User",projectId);
              
              
              //console.log(projectId);
              projectId.push(this.updateProject.id);
              //console.log(projectId);
              
              //console.log("Updated Project Ids with current PJT -> ",projectId);
    
              projectId =  _.uniq(projectId);
              
              
              //console.log("Pjt IDs after removing the duplicates  -> ",projectId);
              
              
              //console.log(projectId);
              let userToUpdate = { ...user, projectId };
      
              
              //console.log("User Object with new Updated Projects  -> ",userToUpdate);
               await usersStore.updateUser(userToUpdate);
            }
          });
          
          const orgPrjUsers = this.state.projecteUsers;
          //console.log("orgPrjUsers", orgPrjUsers);
          //console.log("orgPrjUsers", orgPrjUsers);
          const deletedUsers = _.difference(orgPrjUsers,projectUsers);
          
          await deletedUsers.forEach(async (uid)=>{
            await usersStore.doLoad();
            const user = usersStore.asUserObject({ uid });
            
            if(user){
              let projectId = [...user.projectId];//currentUser.projectId;
              projectId.push(this.updateProject.id);
              
              projectId = _.without(projectId,this.updateProject.id);
              //console.log("projectID", projectId);
            
              let userToUpdate = { ...user, projectId };
               await usersStore.updateUser(userToUpdate);
            }
           
          });
          
          this.currentProject.isArchived = this.updateProject.isArchived;
          this.currentProject.hasSensitiveData = this.updateProject.hasSensitiveData;
          // this.usersStore.doLoad();
          // this.projectsStore.doLoad();
          // const u = this.usersStore;
          // const p = this.projectsStore;
          // console.log("users", u);
          // console.log("projs", p);
          //console.log("PropsFromProjectConfig", this.props);
          
        runInAction(() => {
          this.formProcessing = false;
        });
        this.cleanUp();
        displaySuccess('Updated project successfully');
        //this.goto('/projects');
        // window.location.reload();
        }
    } 
    catch (err) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(err);
    }
  });

  renderButtons() {
    const processing = this.formProcessing;

    const makeButton = ({ label = '', color = 'blue', floated = 'left', ...props }) => {
      return (
        <Button color={color} floated={floated} disabled={processing} className="ml2" {...props}>
          {label}
        </Button>
      );
    };

    const editButton = this.view === 'detail' ? makeButton({ label: 'Edit', onClick: this.handleClickEditButton.bind(this) }) : '';

    const saveButton = this.view === 'edit' ? makeButton({ label: 'Save', onClick: this.handleClickSubmitButton }) : '';

    const cancelButton = makeButton({
      label: 'Cancel',
      floated: 'right',
      color: 'grey',
      onClick: this.handleClickCancelButton,
    });

    return (
      <div className="mt3 mb3">
        <Modal.Actions>
          {cancelButton}
          {saveButton}
          {editButton}
        </Modal.Actions>
      </div>
    );
  }
  
  
setProjectResearchers(isInitialData=false){
  const usersStore = this.props.usersStore;
    let projectAdminsOption = usersStore.asDropDownOptions();
    //console.log("Before filtering",projectAdminsOption);
    
   // let currentProjectResearchUsers = _.map(this.updateProject.projectResearchers, uid => usersStore.asUserObject({ uid }));
    const prjResearchers =!isInitialData? this.updateProject.projectResearchers : this.props.projectResearchers;
    const prjAdmins = !isInitialData?this.updateProject.projectAdmins : this.props.projectResearchers;
    const prjViewers = !isInitialData? this.updateProject.projectViewers: this.props.projectResearchers;
    //console.log("Project Researchers ",prjResearchers);
     //console.log("Current Project ",this.updateProject);
    
    if(prjResearchers){
      projectAdminsOption = _.map(projectAdminsOption, function(pjtAdmins) {
        //console.log("pjt admin",pjtAdmins.key);
       
      //let isResearchUser =  _.find(prjResearchers, pjtAdmins.key);
      let isResearchUser=[];
      let isResearchViewers=[];
      
      
         isResearchUser =  _.map(prjAdmins, (researcher) => {
          if(pjtAdmins.key == researcher){
            return researcher;
          }
        });
       isResearchUser =   _.without(isResearchUser, undefined);
      //console.log(isResearchUser);
      
      isResearchViewers =  _.map(prjViewers, (viewer) => {
          if(pjtAdmins.key == viewer){
            return viewer;
          }
        });
       isResearchViewers =   _.without(isResearchViewers, undefined);
      //console.log(isResearchViewers);
      
      
      if(!isResearchUser.length && !isResearchViewers.length){
        return pjtAdmins;
      }
      });
      // Remove undefines from the array
      const researchers = _.without(projectAdminsOption, undefined);
      //console.log("After filtering",researchers);
      
    this.setState({
      ProjectResearchers: researchers
    });
    

    }
}

setProjectViewers(){
    const usersStore = this.props.usersStore;
    //console.log("userStoreProjViewers", usersStore);
    let projectAdminsOption = usersStore.asDropDownOptions();
    //console.log("Before filtering",projectAdminsOption);
    
    
    const prjResearchers = this.updateProject.projectResearchers;
    const prjAdmins = this.updateProject.projectAdmins;
    const prjViewers = this.updateProject.projectViewers;
    
    if(prjViewers){
      projectAdminsOption = _.map(projectAdminsOption, function(pjtAdmins) {
      
      //console.log("pjt admin",pjtAdmins.key);
       
      //let isResearchUser =  _.find(prjResearchers, pjtAdmins.key);
      let isAdminUser=[];
      let isPjtResearcher=[];
      
      
         isAdminUser =  _.map(prjAdmins, (admin) => {
          if(pjtAdmins.key == admin){
            return admin;
          }
        });
       isAdminUser =   _.without(isAdminUser, undefined);
      //console.log(isAdminUser);
      
      isPjtResearcher =  _.map(prjResearchers, (researcher) => {
          if(pjtAdmins.key == researcher){
            return researcher;
          }
        });
       isPjtResearcher =   _.without(isPjtResearcher, undefined);
      //console.log(isPjtResearcher);
      
      
      if(!isAdminUser.length && !isPjtResearcher.length){
        return pjtAdmins;
      }
      });
      // Remove undefines from the array
      const viewers = _.without(projectAdminsOption, undefined);
      //console.log("After filtering",viewers);
      
    this.setState({
      ProjectViewers: viewers
    });
    

    }
}

setProjectAdmins(){
    const usersStore = this.props.usersStore;
    let projectAdminsOption = usersStore.asDropDownOptions();
    //console.log("Before filtering",projectAdminsOption);
    
    
    const prjResearchers = this.updateProject.projectResearchers;
    const prjAdmins = this.updateProject.projectAdmins;
    const prjViewers = this.updateProject.projectViewers;
    
    if(prjViewers){
      projectAdminsOption = _.map(projectAdminsOption, function(pjtAdmins) {
      
      //console.log("pjt admin",pjtAdmins.key);
       
      //let isResearchUser =  _.find(prjResearchers, pjtAdmins.key);
      let isAdminUser=[];
      let isPjtResearcher=[];
      
      
         isAdminUser =  _.map(prjViewers, (admin) => {
          if(pjtAdmins.key == admin){
            return admin;
          }
        });
       isAdminUser =   _.without(isAdminUser, undefined);
      //console.log(isAdminUser);
      
      isPjtResearcher =  _.map(prjResearchers, (researcher) => {
          if(pjtAdmins.key == researcher){
            return researcher;
          }
        });
       isPjtResearcher =   _.without(isPjtResearcher, undefined);
      //console.log(isPjtResearcher);
      
      
      if(!isAdminUser.length && !isPjtResearcher.length){
        return pjtAdmins;
      }
      });
      // Remove undefines from the array
      const viewers = _.without(projectAdminsOption, undefined);
      //console.log("After filtering",viewers);
      
    this.setState({
      ProjectAdmins: viewers
      //ProjectAdmins: prjAdmins
    });
    

    }
}

setComplianceCategories(){
  
}

renderProjectResearchersSelection() {
    const usersStore = this.props.usersStore;
    // let projectAdminsOption = usersStore.asDropDownOptions();
    // console.log("Before filtering",projectAdminsOption);
    
    let currentProjectResearchUsers = _.map(this.currentProject.projectResearchers, uid => usersStore.asUserObject({ uid }));
    // const prjResearchers = this.currentProject.projectResearchers;
    // const prjAdmins = this.currentProject.projectAdmins;
    // const prjViewers = this.currentProject.projectViewers;
    // console.log("Project Researchers ",prjResearchers);
    // console.log("Current Project ",this.currentProject);
    
    // if(prjResearchers){
    //   projectAdminsOption = _.map(projectAdminsOption, function(pjtAdmins) {
    //     console.log("pjt admin",pjtAdmins.key);
       
    //   //let isResearchUser =  _.find(prjResearchers, pjtAdmins.key);
    //   let isResearchUser=[];
    //   let isResearchViewers=[];
      
      
    //     isResearchUser =  _.map(prjAdmins, (researcher) => {
    //       if(pjtAdmins.key == researcher){
    //         return researcher;
    //       }
    //     });
    //   isResearchUser =   _.without(isResearchUser, undefined);
    //   console.log(isResearchUser);
      
    //   isResearchViewers =  _.map(prjViewers, (viewer) => {
    //       if(pjtAdmins.key == viewer){
    //         return viewer;
    //       }
    //     });
    //   isResearchViewers =   _.without(isResearchViewers, undefined);
    //   console.log(isResearchViewers);
      
      
    //   if(!isResearchUser.length && !isResearchViewers.length){
    //     return pjtAdmins;
    //   }
    //   });
    //   // Remove undefines from the array
    //   projectAdminsOption = _.without(projectAdminsOption, undefined);
    //   console.log("After filtering",projectAdminsOption);

    // }
    
    //this.setProjectResearchers();
    const projectAdminsOption =  this.state.ProjectResearchers;
    
    return (
      <Dropdown
        options={projectAdminsOption}
        defaultValue={_.map(currentProjectResearchUsers, x => x.uid)}
        fluid
        multiple
        selection
        search
        disabled={this.updateProject.isArchived}
        onChange={this.handleProjectResearchersSelection}
      />
    );
  }

  handleProjectResearchersSelection = (e, { value }) => {
    runInAction(() => {
      this.updateProject.projectResearchers = value;
      this.setProjectViewers();
      this.setProjectAdmins();
    });
  };
  
  renderProjectViewersSelection() {
    const usersStore = this.props.usersStore;
    const projectAdminsOption = this.state.ProjectViewers;//usersStore.asDropDownOptions();
    const currentProjectViewersUsers = _.map(this.currentProject.projectViewers, uid => usersStore.asUserObject({ uid }));
    return (
      <Dropdown
        options={projectAdminsOption}
        defaultValue={_.map(currentProjectViewersUsers, x => x.uid)}
        fluid
        multiple
        selection
        search
        onChange={this.handleProjectViewersSelection}
        disabled={this.updateProject.isArchived}
      />
    );
  }

  handleProjectViewersSelection = (e, { value }) => {
    runInAction(() => {
      this.updateProject.projectViewers = value;
      this.setProjectResearchers();
      this.setProjectAdmins();
    });
  };

  renderProjectAdminsSelection() {
    const userStore = this.props.userStore;
    const user = userStore.id;
    const usersStore = this.props.usersStore;
    let projectAdminsOption = this.state.ProjectAdmins;//usersStore.asDropDownOptions();
    //console.log("projectAdminsOption", projectAdminsOption);
    const indexOfObject = projectAdminsOption.findIndex(object => {
      return object.key === user;
    });
    const currentProjectAdminUsers = _.map(this.currentProject.projectAdmins, uid => usersStore.asUserObject({ uid }));
    const defaultValue = _.map(currentProjectAdminUsers, x => x.uid);
    
    const updatedPjtAdmins = this.updateProject.projectAdmins;
    // let selectedUser = currentProjectAdminUsers
    
    //   this.setState({
    // counter: this.state.counter + this.props.increment,
    // });   
    //console.log("AdminUsers",currentProjectAdminUsers);
    return (
      <Dropdown
        options={projectAdminsOption}
        defaultValue={defaultValue}
        fluid
        multiple
        selection
        search
        onChange={this.handleProjectAdminsSelection}
        disabled={this.updateProject.isArchived}
        className={updatedPjtAdmins.length <= 1 ?"project-admin-dropdown disable-close":"project-admin-dropdown"}
      />
    );
  }
  
  handleProjectAdminClose = ()=>{
    
    setTimeout(()=>{
      let adminDropdown = document.querySelector(".project-admin-dropdown");
      let dropdownSelectedItems =  document.querySelectorAll('.project-admin-dropdown a.ui.label');
      
      if(dropdownSelectedItems.length >1){
        adminDropdown.classList.remove('disable-close');
      }
      
    },500);
   
  
  }

  handleProjectAdminsSelection = (e, { value }) => {
    runInAction(() => {
      let adminDropdown = document.querySelector(".project-admin-dropdown");
      let dropdownSelectedItems =  document.querySelectorAll('.project-admin-dropdown a.ui.label');
      
      if(value.length ==1){
        adminDropdown.classList.add('disable-close');
      }else{
        adminDropdown.classList.remove('disable-close');
      }
      
      if (value.length === 0) {
        //this.state.projectAdminsOption =  this.updateProject.projectAdmins;
        alert("There must be at least 1 Project Owner before submitting");
        e.preventDefault();
        e.stopPropagation();
        this.handleModalOpen();
        this.handleClickEditButton();
        this.renderEditView();
      }else{
        this.updateProject.projectAdmins = value;
        this.setProjectViewers();
        this.setProjectResearchers();
      }
      //console.log("UpdateProjAdmins", this.updateProject.projectAdmins);
    });
  };
  
  renderisArchivedSelection() {
    return (
      <Checkbox onChange={this.handleArchivedSelection}   checked={this.updateProject.isArchived}/>
    );
  }

  handleArchivedSelection = (e,data) => {
    runInAction(() => {
      this.updateProject.isArchived = data.checked;
       
    });
  };
  
  renderHasSensitiveDataSelection() {
    const user = this.getUser();
    return (
      <Checkbox onChange={this.handleHasSensitiveData} disabled={this.updateProject.isArchived || !user.isAdmin}  checked={this.updateProject.hasSensitiveData}/>
    );
  }

  handleHasSensitiveData = (e,data) => {
    runInAction(() => {
      this.updateProject.hasSensitiveData = data.checked;
       
    });
  };

  renderField(name, component, contentRenderer) {
    const fields = this.addProjectFormFields;
    const explain = fields[name].explain;
    const label = fields[name].label;
    const hasExplain = !_.isEmpty(explain);
    let content = this.currentProject[name];
    if (contentRenderer && typeof contentRenderer === 'function') {
      content = contentRenderer(content);
    }
    content = _.isEmpty(content) ? 'N/A' : content;

    return (
      <div>
        <Header className="mr3 mt0" as="h4" color="grey">
          {label}
        </Header>
        {hasExplain && (
          <div className="mb2">
            {explain} <span>{this.view === 'detail' ? content : ''}</span>
          </div>
        )}
        <div className="ui field input block m0">{component}</div>
      </div>
    );
  }

  getStore() {
    return this.props.projectsStore;
  }
  
  getUserStore() {
    return this.props.userStore;
  }

  get userStore() {
    return this.props.userStore;
  }

  get usersStore() {
    return this.props.usersStore;
  }

  get awsAccountsStore() {
    return this.props.awsAccountsStore;
  }

  get projectStore() {
    return this.props.projectsStore.getProjectStore();
  }

  get projectsStore() {
    return this.props.projectsStore;
  }
}

// Using the MobX 4 way to use decorators without decorator syntax
decorate(ProjectConfigure, {
  formProcessing: observable,
  modalOpen: observable,
  view: observable,
  updateProject: observable,

  projectsStore: computed,
  awsAccountsStore: computed,
  usersStore: computed,
  userStore: computed,

  handleClickSubmitButton: action,
});
export default observer(ProjectConfigure);
