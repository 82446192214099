/*
 * Modification of DeleteStudyFiles.js ("Delete Files" button)
 * to create buttons for deleting individual files (represented by trash can icon).
 * To be used in StudyRow.js.
 * 
 * Created by: Hunter Irving
 * Date: 11/10/2022
*/

import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, decorate, observable, runInAction } from 'mobx';
import { Button, Header, Modal, Popup } from 'semantic-ui-react';
import { deleteStudyFile } from '../../helpers/api';
import { displayError, displaySuccess } from '@amzn/base-ui/dist/helpers/notification';

// expected props
// - studyId
class DeleteSingleStudyFile extends React.Component {
    constructor(props) {
        super(props);
        runInAction(() => {
            this.modalOpen = false;
        });
    }

    onDeleteDataSetHandler = (fileName, studyid)=>{
        try{
          runInAction(async () => {
              const deleteFile = await deleteStudyFile(studyid, fileName);
              if (deleteFile) {
                displaySuccess(`${fileName} was deleted successfully`);
              }
            });
        }
        catch (err) {
          displayError(err);
        }
    }

    cleanUp() {
        runInAction(() => {
            this.modalOpen = false;
        });
    }

    handleModalClose = () => {
        this.cleanUp();
    };

    handleClickCancelButton = () => {
        runInAction(() => {
            this.cleanUp();
        });
    }

    render() {
        
        return (
            <Modal
                closeIcon
                trigger={this.renderTrigger()}
                open={this.modalOpen}
                onClose={this.handleModalClose}
                className="dataDeleteModal"
            >
                <div className="mt2 animated fadeIn">
                    <Header as="h3" icon textAlign="center" className="mt3" color="grey">
                        Confirm File Deletion
                    </Header>
                    <div className="mx3 animated fadeIn">
                        Click "Delete" to permanently delete '{this.props.file.filename}'.
                        This action cannot be undone.
                    </div>
                    <Button
                        floated="right"
                        color="blue"
                        icon
                        className="ml2"
                        onClick={this.onDeleteDataSetHandler.bind(this, this.props.file.filename, this.props.studyid.studyid)}
                    >
                        Delete
                    </Button>
                    <Button
                        floated="right"
                        color="grey"
                        icon
                        className="ml2"
                        onClick={this.handleClickCancelButton}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        );
    }

    renderTrigger() {
        return (
            <Popup
                trigger={<button class="ui icon button" onClick={action(() => {this.modalOpen = true;})}><i aria-hidden="true" class="trash icon"></i></button>}
                content='Delete File'
            />
        );
       
    }
}

decorate(DeleteSingleStudyFile, {
    modalOpen: observable,
});

export default inject('studiesStoresMap')(observer(DeleteSingleStudyFile));
