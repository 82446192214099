/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

// import React from 'react';
// import { Bar } from 'react-chartjs-2';

// import { barOptions } from './graph-options';

import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';



class PieGraph extends React.Component {
    
    chartOptions = {
  //labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      //data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(206, 0, 88,0.9)',
        'rgb(237, 139, 0,0.9)'
      ],
      borderColor: [
        'rgba(206, 0, 88,1)',
        'rgb(237, 139, 0,1)'
      ],
      borderWidth: 1,
    },
  ],
};
    
    constructor(props) {
        super(props);
        this.chartOptions['labels'] = this.props.labels;
        this.chartOptions['datasets'][0]['data'] = this.props.data;
        
    }
    
    componentDidMount(){
        
    }
    
    
  

render() {
    const data = this.props.data;
    return (data && <Pie data={this.chartOptions} />
    );
  }
  
}

// const PieGraph = () => {
//   return (
//     <Pie data={data} />
//   );
// };

export default PieGraph;