/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

/* eslint-disable max-classes-per-file */

import React from 'react';

import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { computed, decorate,action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Header, Icon, Segment, Button, Checkbox } from 'semantic-ui-react';
import { swallowError } from '@amzn/base-ui/dist/helpers/utils';
import {
  isStoreLoading,
  isStoreError,
  isStoreReady,
  isStoreEmpty,
  stopHeartbeat,
} from '@amzn/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';


import ReactTable from 'react-table';

import StudyRow from './StudyRow';
import { categories } from '../../models/studies/categories';

import { Datasets } from '../../models/resources/page-properties.js';

import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';

// expected props
// - category (an object with the shape { name, id})
// - studiesStoresMap (via injection)
// - userStore (via injection)
class StudiesTab extends React.Component {
  
  constructor(props) {
    super(props);
    this.state ={
      activeStudy: null
    }
  }
  get studiesStore() {
    return this.props.studiesStoresMap[this.props.category.id];
  }

  componentDidMount() {
    const store = this.studiesStore;
    if (!store) return;
    if (!isStoreReady(store)) swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    stopHeartbeat(this.studiesStore);
  }

  get canCreateStudy() {
    return _.get(this.props.userStore, 'user.capabilities.canCreateStudy', true) && this.hasProjects;
  }

  get getUserRole() {
    return _.get(this.props.userStore, 'user.userRole', true);
  }

  get canSelectStudy() {
    const can = _.get(this.props.userStore, 'user.capabilities.canSelectStudy', true);
    if (!can) return can; // If can't select study, then return early, no need to examine if the user is internal and does not have projects
    if (!this.isExternalUser) return this.hasProjects;

    return can;
  }

  get isExternalUser() {
    // Both external guests and external researchers are considered external users
    return _.get(this.props.userStore, 'user.isExternalUser', true);
  }

  get hasProjects() {
    return _.get(this.props.userStore, 'user.hasProjects', true);
  }

  render() {
    const studiesStore = this.studiesStore;
    if (!studiesStore) return null;

    // Render loading, error, or tab content
    let content;
    if (isStoreError(studiesStore)) {
      content = <ErrorBox error={studiesStore.error} className="mt3 mr0 ml0" />;
    } else if (isStoreLoading(studiesStore)) {
      content = <BasicProgressPlaceholder segmentCount={3} className="mt3 mr0 ml0" />;
    } else if (isStoreEmpty(studiesStore)) {
      content = this.renderEmpty();
    } else {
      content = this.renderContent();
    }

    return content;
  }
  
  handleViewDetail = (study) => {
    const selection = this.props.filesSelection;
    selection.cleanup();
    console.log('handleViewDetail',study);
    this.setState({activeStudy:study});
    this.props.setActiveStudy(study);
    //const goto = gotoFn(this);
    //goto('/projectfolders/id/'+study.id);
    //this.goto(`${Datasets["prop7"]}/details/${study.id}`);
    // const link = {
    // "pathname":`${Datasets["prop7"]}/details/${study.id}`,
    // "hash": ""
    // }
    
    // this.props.history.push(link);
    //window.location = '/projectfolders/id/'+study.id;//"/workspaces/create";
  };
  
  handleFileSelection = study => {
    const selection = this.props.filesSelection;
    if (selection.hasFile(study.id)) {
      selection.deleteFile(study.id);
    } else {
      const { id, name, description } = study;
      // TODO: actually do different statuses?
      selection.setFile({ id, name, description, accessStatus: 'approved' });
    }
    this.props.setActiveStudy(null);
  };
  
  goto(pathname) {
    const goto = gotoFn(this);
    goto(pathname);
  }
  
  getUserDisplayName() {
    return this.props.userDisplayName;
  }
  
  renderProjectsTable(data) {
    const isSelectable = this.canSelectStudy;
    const selection = this.props.filesSelection;
   
    return (
      <div>
        <ReactTable
          data={data}
          defaultSorted={[{ id: 'name', desc: false }]}
          showPagination={false}
         // defaultPageSize={pageSize}
          className="-striped -highlight"
          filterable
          defaultFilterMethod={(filter, row) => {
            const columnValue = String(row[filter.id]).toLowerCase();
            const filterValue = filter.value.toLowerCase();
            return columnValue.indexOf(filterValue) >= 0;
          }}
          sortable={[
              'name'
          ]}
          columns={[
            {
              Header: ` `,
              accessor: 'id',
              Cell: observer(cell => {
              
              //{isSelectable && <Checkbox checked={isSelected} style={{ marginTop: '17px' }} />}
                
                const originalRow = cell.original;
                const isSelected = selection.hasFile(originalRow.id);
                //const isSelectable = this.props.isSelectable; //&& this.study.state.canSelect;;
                //const isSelectable = this.canSelectStudy;
                // const attrs = {};
               // if (isSelected) attrs.color = 'blue';
                const onClickAttr = {};
                if (isSelectable) onClickAttr.onClick = () => this.handleFileSelection(originalRow);
                //console.log('onClickAttr', onClickAttr);
                return (
                  <div {...onClickAttr}>
                    <Checkbox checked={isSelected} style={{ marginTop: '17px' }} />
                  </div>
                );
              }),
              filterable: false
            },
            {
              Header: `Project Folder Name`,
              accessor: 'name'
              /*Cell: observer(cell => {
                const originalRow = cell.original;
                return (
                  <div style={{ textAlign: 'center' }}>
                    <a href="#" onClick={(evt) => { this.handleViewDetail(originalRow) }}>
                      {originalRow.name}
                    </a>
                  </div>
                );
              }),*/
            },
            {
              Header: `Created At`,
              accessor: 'createdAt',
              Cell: observer(cell => {
                const projCell = cell.original;
                const createdAt = projCell.createdAt;
                let date = new Date(createdAt);
                const month =  ("0" + (date.getMonth() + 1)).slice(-2);
                return `${date.getFullYear()}-${month}-${date.getDate()}`;
              }),
              filterMethod: (filter, row) => {
                /* get raw values */
                const createdAt = row.createdAt;
                const filterValue = filter.value;
                
                /* build a date string */
                let date = new Date(createdAt);
                const month =  ("0" + (date.getMonth() + 1)).slice(-2);
                const createdAtString = `${date.getFullYear()}-${month}-${date.getDate()}`;
                
                /* compare date string against filter value */
                if (createdAtString.indexOf(filterValue) >= 0) {
                  return true;
                }
                else {
                  return false;
                }
              }
            },
            {
              Header: `Created By`,
              accessor: 'createdBy',
               Cell: observer(cell => {
                const createdCell = cell.original;
                const createdby = createdCell.createdBy;
                const userDisplayName = this.getUserDisplayName();
                const username = userDisplayName.getDisplayName({ uid: createdby });
                return (<div>{username}</div>);
              }),
              filterMethod: (filter, row) => {
                const createdBy = row.createdBy;
                const filterValue = filter.value.toLowerCase();
                const userDisplayName = this.getUserDisplayName();
                const displayName = userDisplayName.getDisplayName({ uid: createdBy }).toLowerCase();
                if(displayName.indexOf(filterValue) >= 0) {
                  return true;
                }
                else {
                  return false;
                }
              }
            },
            {
              Header: `Description`,
              accessor: 'description'
            },
            {
              Header: `Project ID `,
              accessor: 'projectId'
            },
            {
              Header: 'Details',
              accessor: 'viewDetail',
              Cell: observer(cell => {
                const originalRow = cell.original;
                return (
                  <div style={{ textAlign: 'center' }}>
                    <Button basic size="mini" className="mt1 mb1 ml2" onClick={(evt) => { this.handleViewDetail(originalRow) }}>
                      View Detail
                    </Button>
                  </div>
                );
              }),
              filterable: false
            }
          ]}
        />
      </div>
    );
  }

  renderContent() {
    const studiesStore = this.studiesStore;
    const isSelectable = this.canSelectStudy;
    const getUserRole = this.getUserRole;
    const activeStudy = this.state.activeStudy;
    return (
      <div className="mt3 mr0 ml0">
        {activeStudy ? <StudyRow key={activeStudy.id} study={activeStudy} userRole={getUserRole} isSelectable={isSelectable} />: this.renderProjectsTable(studiesStore.list)}
        {/*studiesStore.list.map(study => (
          <StudyRow key={study.id} study={study} userRole={getUserRole} isSelectable={isSelectable} />
        ))*/}
       
      </div>
    );
  }

  renderEmpty() {
    const categoryId = this.props.category.id;
    const isOpenData = false;//categoryId === categories.openData.id;
    const isOrgData = categoryId === categories.organization.id;
    const canCreateStudy = this.canCreateStudy;

    let header = `No ${Datasets["prop2"]}`;
    let subheader = canCreateStudy ? (
      <>
        To create a {Datasets["prop5"]}, click on the <b>Create {Datasets["prop3"]}</b> button at the top.
      </>
    ) : (
      ''
    );

    if (isOpenData) {
      header = `No ${Datasets["prop6"]} from the Open Data project`;
      subheader = 'The information in this page is updated once a day, please come back later.';
    }

    if (isOrgData) {
      header = `No ${Datasets["prop6"]} shared with you`;
      subheader = (
        <>
          <div>
           {Datasets["prop1"]} created at the organization level can be shared but you don&apos;t have any that is shared with you.
          </div>
          {canCreateStudy && (
            <div>
              You can create one yourself by clicking on the <b>Create Data Set</b> button at the top.
            </div>
          )}
          {!canCreateStudy && (
            <div>
              Consider viewing the Open Data {Datasets["prop2"]} by clicking on the <span>Open Data</span> tab above.
            </div>
          )}
        </>
      );
    }

    return (
      <Segment placeholder className="mt3">
        <Header icon className="color-grey">
          <Icon name="clipboard outline" />
          {header}
          <Header.Subheader>{subheader}</Header.Subheader>
        </Header>
      </Segment>
    );
  }
}

decorate(StudiesTab, {
  handleFileSelection: action,
  handleFilesExpanded: action,
  studiesStore: computed,
  canCreateStudy: computed,
  canSelectStudy: computed,
  hasProjects: computed,
  isExternalUser: computed
 // isSelectable: computed
});

export default inject('filesSelection', 'studiesStoresMap', 'userStore', 'userDisplayName')(withRouter(observer(StudiesTab)));
