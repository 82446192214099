/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon, Label, Menu, Table } from 'semantic-ui-react'
import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';

import { decorate, observable, action, runInAction,computed } from 'mobx';
import { Button, Dimmer, Header, List, Loader, Dropdown, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import { displayError } from '@amzn/base-ui/dist/helpers/notification';
import { createLink } from '@amzn/base-ui/dist/helpers/routing';
import validate from '@amzn/base-ui/dist/models/forms/Validate';

import { getAddProjectForm, getAddProjectFormFields } from '../../models/forms/AddProjectForm';

import { Grid, Image, Breadcrumb } from 'semantic-ui-react';

import PieGraph from '../dashboard/graphs/pieGraph';
import {
  BrowserRouter as Router, useParams
} from "react-router-dom";

import {
  isStoreLoading,
  isStoreError,
  isStoreReady,
  isStoreEmpty,
  stopHeartbeat,
} from '@amzn/base-ui/dist/models/BaseStore';

import { swallowError } from '@amzn/base-ui/dist/helpers/utils';
import { Datasets, Environments } from '../../models/resources/page-properties.js';

///vt_sure/addons/addon-base-raas-ui/packages/base-raas-ui/src/parts/dashboard/graphs/pieGraph.js


class ProjectDashboard extends React.Component {
   
  constructor(props) {
    super(props);
    this.state ={
      myDataSetsList :[]
    };
    // this.state = {
    //   // eslint-disable-next-line react/no-unused-state
    //   role: 'guest',
    //   // eslint-disable-next-line react/no-unused-state
    //   status: 'active',
    //   indexId: '',
    // };

    // runInAction(() => {
    //   this.formProcessing = false;
    //   this.validationErrors = new Map();
    //   this.project = {
    //     id: '',
    //     description: '',
    //     indexId: '',
    //     projectAdmins: [],
    //     isArchived:false
    //   };
    // });
    // this.form = getAddProjectForm();
    // this.addProjectFormFields = getAddProjectFormFields();
  }
  
  componentDidMount(){
    
    let myDatasets = this.props.studiesStoresMap['my-studies'];//.list;
    
    if (!isStoreReady(myDatasets)) swallowError(myDatasets.load());
    //myDatasets.startHeartbeat();
    myDatasets.setDashStudies();
    
    runInAction(async () => {
     await myDatasets.setDashStudies();
      let myDataSetsList = myDatasets.listAll;
      console.log(myDataSetsList);
      this.setState({myDataSetsList:myDataSetsList});
    });
    document.querySelector("#app-data").scrollTo(0,0);
  }
  
  getUserDisplayName() {
    return this.props.userDisplayName;
  }
  
  get costsStore() {
    return this.props.scEnvironmentCostsStore;
  }
  
  get envId() {
    return this.props.envId;
  }

  getEnvCostStore(envId) {
    const costsStore = this.costsStore;
    //const envId = this.envId;
    return costsStore.getScEnvironmentCostStore(envId);
  }

  goto(pathname) {
    const location = this.props.location;
    const link = createLink({ location, pathname });
    this.props.history.push(link);
  }
  
  renderBreadcrumb() {
    const { match } = this.props;
    const { params } = match;
    const {instanceId} = params;
    const pr = this.props.projectsStore.getProject(instanceId);
    const projId = pr.id;
    const goto = gotoFn(this);

    return (
      <Breadcrumb className="block mb3">
        <Breadcrumb.Section link onClick={() => goto('/projects')}>
          Projects
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>{projId}</Breadcrumb.Section>
      </Breadcrumb>
    );
  }
  
  handleWorkspace = (envID) => {
    const goto = gotoFn(this);
    goto(`/workspaces/id/${envID}`);
  };
  
  getScCostForEnv = (envId) => {
    const costStore = this.getEnvCostStore(envId);
    if (!isStoreReady(costStore)) swallowError(costStore.load());
    costStore.startHeartbeat();
    if (!costStore.scEnvironmentCost) {
      //console.log("costStoreEnvCost", costStore.scEnvironmentCost);
      return true;
    }
    else {
      const cost = costStore.scEnvironmentCost;
      const initiallist = cost.list;
      const list = initiallist.reverse();
      let costToDate = 0;
      list.forEach(element => {
        costToDate += element.amount;
      });
      costToDate = Number(costToDate).toFixed(2);
      return costToDate;
    }
  };

  render() {
    //console.log("props", this.props);
    const projectUsersLabel =['Project Researchers', 'Project Viewers','Project Admins'];
    let projectUsersData = [0,0,0];
    let totalWorkSpaces =[];
    let dataSetsMap = [];
    let totalDatasets = 0;
    const { match } = this.props;
    const { params } = match;
    
    const {instanceId} = params;
    
    const {myDataSetsList} =this.state || [];
  
    // if()
     
    // {studiesStore.list.map(study => (
    //       <StudyRow key={study.id} study={study} userRole={getUserRole} isSelectable={isSelectable} />
    //     ))}
    const project =this.props.projectsStore.getProject(instanceId);// projectsStore.getProject(instanceId);
    const projId = project.id;
    let hasSens = project.hasSensitiveData;
    if(hasSens){
      hasSens = 'Yes';
    }
    else {
      hasSens = 'No';
    }
    const projCompArray = project.complianceCategories;
    const projComp = projCompArray.join(', ');
    const projProposal = project.proposalID;
    const projFund = project.fundNumber;
    const projIRB = project.IRBprotocolNumber;
    const projDept = project.departmentID;
    
   // let myDatasets = this.props.studiesStoresMap['my-studies'];//.list;
    
    //if (!isStoreReady(myDatasets)) swallowError(myDatasets.load());
    //myDatasets.startHeartbeat();
    //myDatasets.setDashStudies();
    
    
    //let myDataSetsList = myDatasets.listAll;
    //let myDataSetsList = myDatasets.list;
    const finalDataSetList = myDataSetsList.filter(ds => ds.projectId === projId);
   
    
    // let myOrg = this.props.studiesStoresMap['organization'];//.list;
    // if (!isStoreReady(myOrg)) swallowError(myOrg.load());
    // //myOrg.startHeartbeat();
    // let myOrgList = myOrg.list;
    // myOrg = myOrgList.filter(ds => ds.projectId === projId);
    
    
    //totalDatasets = myDatasets.length+myOrg.length;
    totalDatasets = finalDataSetList.length;
    //console.log("datasetlength", totalDatasets);
    
    // if(!totalDatasets.length){
    // // this.props.StudiesStore.doLoad();
    // }
    
    //console.log("projectId", projectId);
    //const scEnvironmentsStore =this.props.projectsStore.scEnvironmentsStore;
    const scEnvsList = this.props.scEnvironmentsStore.filtered();
    
    if(!scEnvsList.length){
       this.props.scEnvironmentsStore.load();
    }
    
    
    if(scEnvsList.length){
      
      totalWorkSpaces = _.filter(scEnvsList, {projectId: instanceId});
    }
   
    
    if(project){
      if(project.projectResearchers?.length){
        projectUsersData[0]=project.projectResearchers?.length;
      }
      
       if(project.projectViewers?.length){
        projectUsersData[1] = project.projectViewers.length;
      }
      
      if(project.projectAdmins?.length){
        projectUsersData[2] = project.projectAdmins.length;
      }
    }
    
    const archived = project.isArchived;
    
    //Used for get the display name via UID
    const userDisplayName = this.getUserDisplayName();
    
    //Get Total Cost for all workspaces
    let totalCost = [];
    totalWorkSpaces.map(env=>{
      totalCost.push(parseFloat(this.getScCostForEnv(env.id)));
    });
    let totalCostFinal = totalCost.reduce((a, b) => a + b, 0);
    totalCostFinal = Number(totalCostFinal).toFixed(2);
    
    return (
      <div className="mt2 animated fadeIn">
      {this.renderBreadcrumb()}
        <Header as="h2" icon textAlign="center" className="mt3" color="grey">
         {projId}{archived && (" (Archived)")}
        </Header>
        <div className="vt-w-100 vt-flex vt-tile-row">
          <div className="vt-tile-table-project">
          <div className="tile-title">Project Metadata</div>
            <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Sensitive</Table.HeaderCell>
                    <Table.HeaderCell>Compliance Categories</Table.HeaderCell>
                    <Table.HeaderCell>Proposal ID</Table.HeaderCell>
                    <Table.HeaderCell>Fund Number</Table.HeaderCell>
                    <Table.HeaderCell>IRB Protocol Number</Table.HeaderCell>
                    <Table.HeaderCell>Departmental Project ID</Table.HeaderCell>
                  </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{hasSens}</Table.Cell>
                  <Table.Cell>{projComp}</Table.Cell>
                  <Table.Cell>{projProposal}</Table.Cell>
                  <Table.Cell>{projFund}</Table.Cell>
                  <Table.Cell>{projIRB}</Table.Cell>
                  <Table.Cell>{projDept}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
          
        <div className="mt3 ml3 mr3 animated fadeIn">
        <div className="vt-flex vt-flex-wrap">
          <div className="vt-w-100 vt-flex vt-justify-center vt-tile-row">
          
          <div class="vt-tile-sm vt-tile-lg secondary vt-flex-grow-1">
            <div class="header">
               ${totalCostFinal}
            </div>
            <div class="title">Total Project Cost</div>
          </div>
          
          <div class="vt-tile-sm vt-tile-lg primary vt-tile-chart  vt-flex-grow-1">
              <div class="header">
                <PieGraph labels={projectUsersLabel} data={projectUsersData}></PieGraph>
              </div>
              <div class="title">User Metrics</div>
          </div>
            
          </div>
          
          <div className="vt-w-100 vt-flex vt-tile-row">
           
            
          <div class="vt-tile-sm  primary  vt-flex-grow-1">
              <div class="header">
              {totalWorkSpaces.length}
              </div>
              <div class="title">Total {Environments["prop1"]}</div>
          </div>
          
          <div class="vt-tile-sm  secondary  vt-flex-grow-1">
              <div class="header">
              {totalDatasets}
              </div>
              <div class="title">Total {Datasets["prop2"]}</div>
          </div>
          </div>
          
          <div className="vt-w-100 vt-flex vt-tile-row">
            <div className="vt-tile-table-project">
            <div className="tile-title">List Of {Environments["prop1"]}</div>  
            
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{Environments["prop2"]} Name</Table.HeaderCell>
                <Table.HeaderCell>Created By</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
                <Table.HeaderCell>{Environments["prop2"]} Type</Table.HeaderCell>
                <Table.HeaderCell>Cost To Date</Table.HeaderCell>
                <Table.HeaderCell>Attached {Datasets["prop2"]}</Table.HeaderCell>
              </Table.Row>
          </Table.Header>

            <Table.Body>
            {totalWorkSpaces && totalWorkSpaces.map(env=>{
            let createdAt = env.createdAt;
            createdAt = createdAt.split('T')[0];
            let ds = env.studyIds;
            ds = ds.join(', ');
              return (<Table.Row>
                <Table.Cell>
                  <a href="#" onClick={()=>{this.handleWorkspace(env.id)}}><Label>{env.name}</Label></a>
                </Table.Cell>
                <Table.Cell>{userDisplayName.getDisplayName({ uid: env.createdBy })}</Table.Cell>
                <Table.Cell>{createdAt}</Table.Cell>
                <Table.Cell>{env.envTypeConfigId}</Table.Cell>
                <Table.Cell>${this.getScCostForEnv(env.id)}</Table.Cell>
                <Table.Cell>{ds}</Table.Cell>
              </Table.Row>);
            })
            }
              
             
            </Table.Body>
          </Table>
             
            </div>
            
            <div className="vt-tile-table-project">
            <div className="tile-title">List of {Datasets["prop2"]}</div> 
                         
                         
                         <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Created By</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
              </Table.Row>
          </Table.Header>

            <Table.Body>
              {finalDataSetList && finalDataSetList.map(env=>{
              let created = env.createdAt;
              created = created.split('T')[0];
              let cat = env.category;
              if(cat === 'My Studies'){
                cat = `My ${Datasets["prop3"]}`;
              }
              else if(cat === 'Organization'){
                cat = `Organization ${Datasets["prop3"]}`;
              }
              else {
                cat = 'N/A';
              }
                return (<Table.Row>
                  <Table.Cell>
                    <Label>{env.name}</Label>
                  </Table.Cell>
                  <Table.Cell>{env.description}</Table.Cell>
                  <Table.Cell>{userDisplayName.getDisplayName({ uid: env.createdBy })}</Table.Cell>
                  <Table.Cell>{created}</Table.Cell>
                </Table.Row>);
                })
              }
              {/*{myOrg && myOrg.map(env=>{
              let created = env.createdAt;
              created = created.split('T')[0];
              let cat = env.category;
              if(cat === 'My Studies'){
                cat = 'My Data Sets';
              }
              else if(cat === 'Organization'){
                cat = 'Organization Data Sets';
              }
              else {
                cat = 'N/A';
              }
                return (<Table.Row>
                  <Table.Cell>
                    <Label>{env.name}</Label>
                  </Table.Cell>
                  <Table.Cell>{env.description}</Table.Cell>
                  <Table.Cell>{userDisplayName.getDisplayName({ uid: env.createdBy })}</Table.Cell>
                  <Table.Cell>{created}</Table.Cell>
                  <Table.Cell>{cat}</Table.Cell>
                </Table.Row>);
                })
              */}
            </Table.Body>
          </Table>
           
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
  
  
  
  // eslint-disable-next-line react/no-unused-state
  handleRoleChange = (e, { value }) => this.setState({ role: value });

  // eslint-disable-next-line react/no-unused-state
  handleStatusChange = (e, { value }) => this.setState({ status: value });

  renderAddProjectForm() {
    const processing = this.formProcessing;
    const fields = this.addProjectFormFields;
    const toEditableInput = (attributeName, type = 'text') => {
      const handleChange = action(event => {
        event.preventDefault();
        this.project[attributeName] = event.target.value;
      });
      return (
        <div className="ui focus input">
          <input
            type={type}
            defaultValue={this.project[attributeName]}
            placeholder={fields[attributeName].placeholder || ''}
            onChange={handleChange}
          />
        </div>
      );
    };

    return (
      <Segment basic className="ui fluid form">
        <Dimmer active={processing} inverted>
          <Loader inverted>Checking</Loader>
        </Dimmer>
        {this.renderField('id', toEditableInput('id', 'id'))}
        <div className="mb4" />
        {this.renderField('indexId',this.renderIndexSelection())}
        {/*this.renderIndexSelection()*/}
        <div className="mb4" />
        {this.renderField('description', toEditableInput('description', 'description'))}
        {/*<div className="mb4" />
        {this.renderField('projectAdmins')}
        {this.renderProjectAdminsSelection()}*/}
        {this.renderButtons()}
      </Segment>
    );
  }

  renderProjectAdminsSelection() {
    const projectAdminsOption = this.props.usersStore.asDropDownOptions();
    const fields = this.addProjectFormFields;
    const placeholder = fields.projectAdmins.placeholder || 'Please select a user';

    return (
      <Dropdown
        options={projectAdminsOption}
        placeholder={placeholder}
        fluid
        multiple
        selection
        onChange={this.handleProjectAdminsSelection}
      />
    );
  }

  handleProjectAdminsSelection = (e, { value }) => {
    runInAction(() => {
      if (Array.isArray(value)) {
        this.project.projectAdmins = value;
      }
    });
  };

  renderButtons() {
    const processing = this.formProcessing;
    return (
      <div className="mt3">
        <Button floated="right" color="blue" icon disabled={processing} className="ml2" onClick={this.handleSubmit}>
          Add Project
        </Button>
        <Button floated="right" disabled={processing} onClick={this.handleCancel}>
          Cancel
        </Button>
      </div>
    );
  }

  renderIndexSelection() {
    const indexIdOption = this.props.indexesStore.dropdownOptions;
    return (
      <Dropdown
        options={indexIdOption}
        placeholder="Please assign an index to this project"
        fluid
        selection
        onChange={this.handleIndexSelection}
      />
    );
  }

  handleIndexSelection = (e, { value }) => this.setState({ indexId: value });

  renderField(name, component) {
    const fields = this.addProjectFormFields;
    const explain = fields[name].explain;
    const label = fields[name].label;
    const hasExplain = !_.isEmpty(explain);
    const fieldErrors = this.validationErrors.get(name);
    const hasError = !_.isEmpty(fieldErrors);

    return (
      <div>
        <Header className="mr3 mt0" as="h2" color="grey">
          {label}
        </Header>
        {hasExplain && <div className="mb2">{explain}</div>}
        <div className={`ui big field input block m0 ${hasError ? 'error' : ''}`}>{component}</div>
        {hasError && (
          <div className="ui pointing red basic label">
            <List>
              {_.map(fieldErrors, fieldError => (
                <List.Item key={name}>
                  <List.Content>{fieldError}</List.Content>
                </List.Item>
              ))}
            </List>
          </div>
        )}
      </div>
    );
  }

  handleCancel = action(event => {
    event.preventDefault();
    event.stopPropagation();
    this.formProcessing = false;
    this.goto('/projects');
  });

  handleSubmit = action(async () => {
    this.formProcessing = true;
    try {
      // Perform client side validations first
      this.project.indexId = this.state.indexId;
      const validationResult = await validate(this.project, this.addProjectFormFields);
      
       
      
      
      // if there are any client side validation errors then do not attempt to make API call
      //if (validationResult.fails()) {
      if (validationResult.fails()) {
        runInAction(() => {
          this.validationErrors = validationResult.errors;
          this.formProcessing = false;
        });
      } else {
        const usersStore = this.getStore();
       const user = this.props.userStore.user;
        
        let projectId = [...user.projectId];
        projectId.push(this.project.id);
        projectId =  _.uniq(projectId);
        
        let userToUpdate = { ...user, projectId };
        
        
        
        
        // There are no client side validation errors so ask the store to add user (which will make API call to server to add the user)
        this.project.projectAdmins =[user.uid];
        this.project.isArchived =false;
        
        runInAction(async () => {
         await usersStore.updateUser(userToUpdate);
          await this.props.projectsStore.addProject(this.project);
        });
       
        
        runInAction(() => {
          this.formProcessing = false;
          this.goto('/projects');
        });
        
      }
    } catch (error) {
      runInAction(() => {
        this.formProcessing = false;
      });
      displayError(error);
    }
  });

  getStore() {
    return this.props.usersStore;
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(ProjectDashboard, {
  formProcessing: observable,
  user: observable,
  validationErrors: observable,
  costsStore: computed,
  envId: computed,
});
export default inject('usersStore','userStore', 'indexesStore', 'projectsStore', 'scEnvironmentsStore','studiesStoresMap','scEnvironmentCostsStore', 'userDisplayName')(withRouter(observer(ProjectDashboard)));
