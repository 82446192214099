/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import { createForm } from '../../helpers/form';

const addProjectFormFields = {
  id: {
    label: 'Project Name',
    placeholder: 'Type name for this project',
    explain: 'The Project Name must be less than 100 characters long with no spaces, and only contain alphanumeric characters, dashes "-", and/or underscores "_"',
    rules: 'required|string|between:1,100|regex:/^[A-Za-z0-9-_]+$/',
    max: '100'
  },
  // indexId: {
  //   label: 'Index ID',
  //   explain: 'An index is required for each project, please select an index',
  //   rules: 'required|string',
  // },
  description: {
    label: 'Description',
    placeholder: 'Type description for this project',
    rules: 'required|string|between:1,3000',
    max: '3000'
  },
  createdBy: {
    label: 'Created By',
  },
  projectAdmins: {
    label: 'Project Owners',
    placeholder: 'Users who automatically have full control of workspaces associated with this project',
    explain: ' ',
    rules: 'array',
  },
  projectResearchers: {
    label: 'Project Researchers',
    placeholder: 'Users who automatically have full control of workspaces associated with this project',
    explain: ' ',
    rules: 'array',
  },
  projectViewers: {
    label: 'Project Viewers',
    placeholder: 'Users who automatically have full control of workspaces associated with this project',
    explain: ' ',
    rules: 'array',
  },
  hasSensitiveData: {
    label: 'Sensitive Project',
    explain: `Check here if PRDP determined that your project involves sensitive data that are subject to additional legal or contractual requirements such as HIPAA, GDPR, or other privacy and data protection laws and agreements. Additional security controls, monitoring, and oversight apply. Please contact era-help@vt.edu if this needs to be changed.`,
    rules: 'boolean'
  },
  isArchived: {
    label: 'Archive Project',
    rules: 'boolean'
  },
  complianceCategories: {
    label: 'Compliance Categories',
    explain: `Select all values that apply to your project. If nothing applies, leave this field blank.`,
    rules: 'array'
  },
  proposalID: {
    label: 'Proposal ID',
    placeholder: 'Alphanumeric - 8 digit maximum',
    explain: `Provide the Summit Proposal ID here if the project is tracked in Summit.`,
    rules: 'max:8|string',
    max: '8'
  },
  fundNumber: {
    label: 'Fund Number',
    placeholder: 'Alphanumeric - 6 digit maximum',
    explain: `Provide the Fund number that is attached to this project.`,
    rules: 'max:6|string',
    max: '6'
  },
  IRBprotocolNumber: {
    label: 'IRB Protocol Number',
    placeholder: 'Format "XX-XXX(X)" where the last digit is as necessary',
    explain: `If PRDP determined that your project is “Sensitive”, you should have an IRB protocol number tracking that determination.`,
    rules: 'max:7|string',
    max: '7'
  },
  departmentID: {
    label: 'Departmental Project ID',
    placeholder: 'Alphanumeric - 50 digit maximum',
    explain: `Provide a unique departmental identifier for your project if you have one.`,
    rules: 'max:50|string',
    max: '50'
  }
};

function getAddProjectFormFields() {
  return addProjectFormFields;
}

function getAddProjectForm() {
  return createForm(addProjectFormFields);
}

export { getAddProjectFormFields, getAddProjectForm };
