/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { decorate, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Link } from 'react-router-dom';
import {Checkbox,Grid,Header,Image,
  Menu,
  Segment,
  Sidebar,
  Label,
  Input,
  Dropdown, Table, Rating,Icon } from 'semantic-ui-react';

import { createLink } from '../helpers/routing';
import { displayError } from '../helpers/notification';
import { branding, version } from '../helpers/settings';


// expected props
// - userStore (via injection)
class MainLayout extends React.Component {
  
  constructor(props){
    super(props);
     this.state = {sideNavVisible: true};
  }
  goto = pathname => () => {
    const location = this.props.location;
    const link = createLink({
      location,
      pathname,
    });

    this.props.history.push(link);
  };

  handleLogout = async event => {
    event.preventDefault();
    event.stopPropagation();

    try {
      await this.props.authentication.logout();
    } catch (error) {
      displayError(error);
    }
  };

  getMenuItems() {
    return this.props.menuItems || [];
  }
  
  sideBarToggle() {
    this.setState({
      sideNavVisible: !this.state.sideNavVisible
    });
  }

  render() {
    const currentUser = this.props.userStore.user;
    const isAdmin = this.props.userStore?.user?.isAdmin;
    const displayName = currentUser ? currentUser.displayName : 'Not Logged In';
    const pathname = _.get(this.props.location, 'pathname', '');
    const is = value => _.startsWith(pathname, value);
    //const appVersion = "1.1.7";
    const appVersion = version;
    const itemsArr = this.getMenuItems();
    const mockMenu = {
        "url": "/users",
        "title": "Administration",
        "icon": "users",
        "shouldShow": true,
        children:[{title:"Authentication",url: "/authentication-providers"},{title:"Dashboard",url: "/admin/dashboard"},{ title: 'Users', icon: 'users',url: "/users" },{ title: 'Accounts', icon: 'sitemap',url: "/Accounts"},
        {
        title: "Workspace Types",
        icon: 'computer',
        url:'/workspace-types-management'
        },
        {url:'/workflows/published', title: 'Workflows', icon: 'fork' },
        { title: 'Data Sources', icon: 'database', url: '/data-sources' }
        ]
    };
    return(
       <Grid columns={1} id="app-container">
        <Grid.Column id="app-header">
          <header className='flex items-center'>
            <div id="sidebar-toggle" onClick={(e, data) => this.sideBarToggle()}>
              <i aria-hidden="true" class="bars link icon"></i>
            </div>
          <div className="flex items-center">
            <Image
              size="mini"
              src={this.props.assets.images.logoImage}
              className="mr1"
              style={{ height: '20px', width: 'auto' }}
            />
            <span id="app-title">{branding.main.title}</span>
          </div>
          <div class="app-user flex items-center flex-auto justify-end">
            <div>
              <Icon name="user circle" />
            </div>
            <Dropdown item text={displayName}>
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
          </div>
          </header>
        </Grid.Column>
        <Grid.Column id="app-content">
          <Sidebar.Pushable as={Segment}>
            <Sidebar
              as={Menu}
              animation='push'
              icon='labeled'
              inverted
              vertical
              visible={this.state.sideNavVisible}
              width='thin'
              id="app-sidebar"
            >
              {_.map(itemsArr, (item, index) => {
                const show = (_.isFunction(item.shouldShow) && item.shouldShow()) || item.shouldShow;
                return (
                  show &&
                  (item.body ? (
                    item.body
                  ) : (
                    <Menu.Item key={index} active={is(item.url)} onClick={is(item.url) ? undefined : this.goto(item.url)} className="menu-item">
                      <div className={`flex items-center ${is(item.url) ? 'active' : ''}`}>
                        <Icon name={item.icon} size="mini" className="menu-item-icon"/>
                        <span className="fs-7 menu-item-text">{item.title}</span>
                      </div>
                    </Menu.Item>
                  ))
                );
              })}
              
              {isAdmin && (<Menu.Item active={is(mockMenu.url)} onClick={is(mockMenu.url) ? undefined : this.goto(mockMenu.url)} className="menu-item">
                <div className={`flex items-center ${is(mockMenu.url) ? 'active' : ''}`}>
                  <Icon name={mockMenu.icon} size="mini" className="menu-item-icon"/>
                  <Dropdown item text={mockMenu.title}>
                    <Dropdown.Menu>
                      {mockMenu?.children.map((data, index) => <Dropdown.Item as={Link} to={data.url} text={data.title}></Dropdown.Item>)}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Menu.Item>)
              }
              <div className="versionWrap">
                <div className="versionDiv">App Version: {appVersion}</div>
              </div>
            </Sidebar>

            <Sidebar.Pusher id="app-data" className={this.state.sideNavVisible?'app-sidebar-open':''}>
              <Segment basic>
                <div
                className="mainLayout fit animated fadeIn"
                key="ml3"
                >
                {this.props.children}
              </div>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Grid.Column>
      </Grid>
      );
    // return [
      // <Menu vertical inverted fixed="left" icon="labeled" key="ml1" style={{ overflowY: 'auto' }}>
      //   <Menu.Item name=" " style={{ height: '40px' }} />
      //   {_.map(itemsArr, (item, index) => {
      //     const show = (_.isFunction(item.shouldShow) && item.shouldShow()) || item.shouldShow;
      //     return (
      //       show &&
      //       (item.body ? (
      //         item.body
      //       ) : (
      //         <Menu.Item key={index} active={is(item.url)} onClick={is(item.url) ? undefined : this.goto(item.url)}>
      //           <Icon name={item.icon} size="mini" />
      //           <span className="fs-7">{item.title}</span>
      //         </Menu.Item>
      //       ))
      //     );
      //   })}
      // </Menu>,

    //   <Menu inverted color="black" fixed="top" className="box-shadow zindex-1500" key="ml2">
    //     <Menu.Item style={{ height: '50px', verticalAlign: 'middle' }}>
    //       <Image
    //         size="mini"
    //         src={this.props.assets.images.logoImage}
    //         className="mr1"
    //         style={{ height: '40px', width: 'auto' }}
    //       />
    //       <span style={{ paddingLeft: '5px' }}>{branding.main.title}</span>
    //       <span style={{ paddingLeft: '20px' }}>{versionAndDate}</span>
    //     </Menu.Item>
    //     <Menu.Menu position="right">
    //       <Menu.Item>
    //         <Icon name="user" /> {displayName}
    //       </Menu.Item>
    //       <Menu.Item name="logout" onClick={this.handleLogout} />
    //     </Menu.Menu>
    //   </Menu>,
      // <div
      //   className="mainLayout fit animated fadeIn"
      //   style={{
      //     paddingTop: '40px',
      //     paddingLeft: '84px',
      //     paddingBottom: '100px',
      //   }}
      //   key="ml3"
      // >
      //   {this.props.children}
      // </div>,
    // ];
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(MainLayout, {
  handleLogout: action,
});

export default inject('authentication', 'userStore', 'assets')(withRouter(observer(MainLayout)));
