/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import { createForm } from '../../helpers/form';
import { categories } from '../studies/categories';
import { Datasets } from '../resources/page-properties.js';

const createStudyFields = {
  // General fields
  id: {
    label: `${Datasets["prop3"]} ID`,
    placeholder: `A unique ID used to reference the ${Datasets["prop5"]}`,
    extra: {
      explain: `The ${Datasets["prop3"]} ID must be less than 100 characters long with no spaces, and only contain alphanumeric characters, dashes "-", and/or underscores "_"`,
    },
    rules: ['required', 'string', 'between:1,100', 'regex:/^[A-Za-z0-9-_]+$/'],
  },
  categoryId: {
    label: '', // not shown because extra.showHeader = false
    extra: {
      explain:
        `If you choose "My ${Datasets["prop3"]}", only you can access it. If you choose "Organization ${Datasets["prop3"]}", you get to decide who can access it.`,
      yesLabel: `My ${Datasets["prop3"]}`,
      noLabel: `Organization ${Datasets["prop3"]}`,
      yesValue: categories.myStudies.id,
      noValue: categories.organization.id,
      showHeader: false,
    }
  },
  name: {
    label: `${Datasets["prop3"]} Display Name`,
    extra: {
      explain: 'Alphanumeric and special characters can be used including spaces',
    },
    placeholder: `A name for the ${Datasets["prop5"]} that can be referred to`,
    rules: ['string', 'max:2048'],
  },
  description: {
    label: 'Description',
    placeholder: `A description of the ${Datasets["prop5"]}`,
    rules: ['required', 'string', 'max:8192'],
  },
  projectId: {
    label: 'Project ID',
    placeholder: `The project ID associated with this ${Datasets["prop5"]}`,
    rules: ['required', 'string', 'min:1', 'max:100'],
  },
};

const getCreateStudyForm = () => {
  return createForm(createStudyFields);
};

export { getCreateStudyForm }; // eslint-disable-line import/prefer-default-export
